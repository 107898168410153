import {connect} from 'react-redux';
import {AirDestinationSearch} from 'airborne/air/homepage';
import {
    changeAirExchangeForm,
    changeAirExchangeRecentSearches,
} from 'airborne/store/modules/exchange_air/actions';
import {
    getAirExchangeBookingKey,
    getAirExchangeForm,
    getAirExchangeModifyFlags, getAirExchangeProvider,
    isAirExchangeFlow, isFirstExchangeOriginDestinationFlown,
} from 'airborne/store/modules/exchange_air/selectors';


import {getPnrForm, getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {
    getAirSearchType,
    getCollapsableSections,
} from 'airborne/store/modules/homepage/selectors/homepage';
import {isFaresLoading} from 'airborne/store/modules/search_air/selectors';
import {isAvailabilityLoading} from 'airborne/store/modules/availability_air/selectors';
import {toggleCollapsableSection} from 'airborne/store/modules/homepage/actions';
import {requestExchangeAvailability, requestExchangeSearch} from 'airborne/store/modules/exchange_air/actions';
import {getAirExchangeUnderlyingProvider} from 'airborne/store/modules/exchange_air/selectors/params';

const SearchForm = connect(state => {
    const {pnr, ...profile} = getPnrProfile(state, 0);
    const {value, errors} = getAirExchangeForm(state);
    const collapsableSections = getCollapsableSections(state);
    const searchType = getAirSearchType(state);
    const loading = isFaresLoading(state) || isAvailabilityLoading(state);
    const {company: {gdsName}} = getPnrForm(state);
    const exchangeKey = getAirExchangeBookingKey(state);
    const firstExchangeOriginDestinationFlown = isFirstExchangeOriginDestinationFlown(state);

    return {
        value,
        errors,
        pnr,
        companyId: profile.company,
        loading,
        collapsableSections,
        searchType,
        gdsName,
        provider: getAirExchangeProvider(state),
        underlyingProvider: getAirExchangeUnderlyingProvider(state),
        modifySearchFlags: getAirExchangeModifyFlags(state),
        isAirExchangeFlow: isAirExchangeFlow(state),
        isFirstExchangeOriginDestinationFlown: firstExchangeOriginDestinationFlown,

        // Custom properties
        editable: true,
        disableRecentSearches: true,
        disableSearch: !exchangeKey,
    };
}, {
    onChange: changeAirExchangeForm,
    onReuseDestination: changeAirExchangeRecentSearches,
    searchAir: requestExchangeSearch,
    toggleCollapsableSection,
    requestAvailability: requestExchangeAvailability,
}
)(AirDestinationSearch);

export default SearchForm;
