import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getAirExchangeCurrentStep} from 'airborne/store/modules/exchange_air/selectors';

import {STEPS} from './types';
import RoutesForm from './Form';
import Availability from './Availability';
import FareSearch from './FareSearch';
import Checkout from './Checkout';
import ManualExchangeForm from './ManualExchangeForm';
import Pricing from 'airborne/air/pricing';

@connect(state => ({
    step: getAirExchangeCurrentStep(state),
}))
export default class RoutesModification extends React.Component {

    static propTypes = {
        // from parent
        modalRef: PropTypes.object,
        // from store state
        step: PropTypes.string,
    }

    componentDidUpdate() {
        const {step, modalRef} = this.props;

        const modalElement = modalRef?.current?.dialog;

        if (step === STEPS.CHECKOUT && modalElement) {
            modalElement.scrollTo(0, 0);
        }
    }

    render() {
        const {step} = this.props;

        return {
            [STEPS.FORM]: <RoutesForm />,
            [STEPS.AVAILABILITY]: <Availability />,
            [STEPS.FARE_SEARCH]: <FareSearch />,
            [STEPS.PRICING]: <Pricing />,
            [STEPS.MANUAL_EXCHANGE]: <ManualExchangeForm />,
            [STEPS.CHECKOUT]: <Checkout />,
        }[step];
    }
}
