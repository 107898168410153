import React from 'react';
import gettext from 'airborne/gettext';
import SelectTags from 'midoffice/newforms/widgets/SelectTags';
import {getAirlineChoices} from '../helpers/common';
import {airlinesAutocomplete} from "airborne/air/homepage/helpers/autocomplete";

export default class AirAirlineCodesField extends React.Component {

    airlineChoices = getAirlineChoices();

    render() {
        return (
            <SelectTags.Field
                label={gettext('Airline codes')}
                labelCol={3}
                choices={this.airlineChoices}
                name="airlineCodes"
                autocompleteSource={airlinesAutocomplete}
            />
        );
    }
}
