import inRange from 'lodash/inRange';
import {createSchema, createShallowSchema, noErrors} from 'midoffice/newforms/helpers';
import {shallowAssign} from 'midoffice/helpers/types';
import {
    Field,
    BooleanField,
    DateRange,
    CharField,
    DigitsField,
    ArrayField,
    ChoiceField,
} from 'midoffice/newforms/fields-stateless';
import {Schema} from 'midoffice/newforms/schema-stateless';
import gettext from 'airborne/gettext';
import moment from 'moment';
import {getDateLimits} from 'airborne/air/homepage/helpers/dateLimits';

import {IdNumbersField} from 'airborne/air/homepage/components/IdNumbersSchema';

const OriginDestinationRange = createShallowSchema(shallowAssign({}, DateRange, {
    validate(value={}, {strict=false, oneWay=false, isFirstExchangeOriginDestinationFlown}={}) {
        const {min: pickup, max: dropoff} = value;
        const {min: today, max: tooFar} = getDateLimits();
        const pickupDate = moment(pickup, 'YYYY-MM-DD');
        const dropoffDate = moment(dropoff, 'YYYY-MM-DD');

        if (
            pickup && pickupDate.diff(today, 'days') < 0 &&
            !isFirstExchangeOriginDestinationFlown
        ) {
            return gettext('Depart date can\'t be in the past');
        }
        if (pickup && pickupDate.diff(tooFar, 'days') >= 0) {
            return gettext('Depart date can\'t be that far in the future');
        }

        if (
            pickup && dropoff && pickupDate > dropoffDate &&
            !isFirstExchangeOriginDestinationFlown
        ) {
            return gettext('Return can\'t be earlier than depart');
        }

        if (!strict && pickup !== null && dropoff !== null) {
            return null;
        }

        if (!pickupDate.isValid() || (!oneWay && !dropoffDate.isValid())) {
            return {
                min: pickupDate.isValid() ? null : gettext('Please enter your depart date'),
                max: oneWay || dropoffDate.isValid() || isFirstExchangeOriginDestinationFlown ?
                    null :
                    gettext('Please enter your return date'),
            };
        }

        return null;
    },
    toRepresentation(formValue, params) {
        let {value, errors} = Schema.toRepresentationFields.call(this, formValue, params);
        const depart = moment(value.min, 'YYYY-MM-DD');
        const arrive = moment(value.max, 'YYYY-MM-DD');
        const departIsEarlier = depart.isValid() && arrive.isValid() && depart.isAfter(arrive);
        const oneWay = params?.oneWay;
        if (oneWay && departIsEarlier) {
            value = {...value, max: null};
        }

        if (noErrors(errors)) {
            errors = this.validate(value, params);
        }

        return {value, errors};
    },

    fields: {
        min: {
            ...DateRange.fields.min,
            inputFormat: 'YYYY-MM-DD',
        },
        max: {
            ...DateRange.fields.max,
            inputFormat: 'YYYY-MM-DD',
        }
    }

}));

const StrictDestinationField = {
    ...Field,

    validate(value, {strict}={}) {
        if (strict && !(value && value.type)) {
            return this.missingDestinationMessage;
        }
    }
};

const SpecialFlightNumber = createShallowSchema({
    fields: {
        vendor: CharField,
        number: {
            ...DigitsField,
            maxLength: 4,
            maxLengthMessage: (field) => gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: () => gettext('Ensure this field has only alphanumeric characters'),
        }
    }
});

const SpecificFlightsField = {
    ...IdNumbersField,
    child: SpecialFlightNumber,
};

const timeRestrictionField = createSchema({
    fields: {
        time: {
            ...Field,
            validate(value) {
                if (!value) return this.validateEmptyValue(value);

                const [hours, minutes] = value.split(':');

                if (!inRange(Number(hours), 25)) {
                    return gettext('Enter a valid time in HH:MM format');
                }

                if (!inRange(Number(minutes), 60)) {
                    return gettext('Enter a valid time in HH:MM format');
                }

                return null;
            }
        },
        timeWindow: Field,
    }
});

export const OriginDestinationField = createShallowSchema({
    ...ArrayField,
    child: createShallowSchema({
        fields: {
            'noCabinPreference': BooleanField,
            'pickUp': {
                ...StrictDestinationField,
                missingDestinationMessage: gettext('Where do you want to fly from?'),
            },
            'dropOff': {
                ...StrictDestinationField,
                missingDestinationMessage: gettext('Where do you want to fly to?'),
            },
            'preserveDest': ChoiceField,
            'dateRange': OriginDestinationRange,
            'timeRestrictionOutbound': timeRestrictionField,
            'timeRestrictionInbound': timeRestrictionField,
            'departureCabin': Field,
            'arrivalCabin': Field,
            'flownIndicator': BooleanField,
            'departureDate': Field,
        }
    }),
});

const AirDestinationSearchSchema = createShallowSchema({
    fields: {
        'tripType': Field,
        'originDestinations': OriginDestinationField,
        'connections': ArrayField,
        'directFlights': BooleanField,
        'allowedBaggage': BooleanField,
        'refundableOnly': BooleanField,
        'excludePenalties': BooleanField,
        'ftNumbers': IdNumbersField,
        'airlineCodes': ArrayField,
        'airlineAlliances': ArrayField,
        'specificFlights': SpecificFlightsField,
    },
});

export default AirDestinationSearchSchema;
