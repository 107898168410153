import classnames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';
import Row from 'react-bootstrap/Row';
import {connect} from 'react-redux';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';

import {PRODUCT_TYPES, setBroadStreetTargets} from 'airborne/cars/homepage/helpers/setBroadStreetTargets';
import AcrissField from 'airborne/cars/homepage/components/Acriss';
import getCarsSearchSchema from 'airborne/cars/homepage/schemas/CarsDestinationSearchSchema';
import CheckinRange from 'airborne/cars/homepage/components/CheckinRange';
import DestinationSelect from 'airborne/cars/homepage/components/DestinationSelect';
import IdNumbers, {AddMore} from 'airborne/cars/homepage/IdNumbers';
import NegotiatedIdNumbers, {AddMoreIDNumber} from 'airborne/cars/homepage/NegotiatedIdNumbers';
import NegotiatedNumbers, {AddMoreNegotiatedNumber} from 'airborne/cars/homepage/NegotiatedNumbers';
import DestinationAutocomplete from 'airborne/cars/homepage/components/DestinationAutocomplete';
import {getCarsModifyQueryAutocompleteFn} from 'airborne/cars/homepage/helpers/autocomplete';
import {
    getNegotiatedIdChoices,
    getNegotiatedChoices,
    getNegotiatedIdCdMatrix,
    getVendorsChoices,
} from 'airborne/cars/homepage/helpers/carVendor';
import {getCdIdMatchedValue} from 'airborne/cars/homepage/helpers/negotiatedHelpers';
import {getCarsDistanceChoices} from 'airborne/cars/homepage/helpers/searchDistance';
import {currentCompany, dropEmpty, noErrors} from 'airborne/cars/homepage/helpers/homepage';

import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {mergeIdNumbers} from 'airborne/store/modules/homepage/reducers/destination';
import {getDestForm, getOptions} from 'airborne/store/modules/homepage/selectors/homepage';
import {getCompanySmid, getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import searchCars from 'airborne/store/modules/search_cars/actions/search';
import {getCarsIdNumbers, isRatesLoading} from 'airborne/store/modules/search_cars/selectors';
import {changeDestination, reuseDestination} from 'airborne/store/modules/homepage/actions';

import {
    carsDestinationLoaded,
    carsDestinationLoading,
    parseCarBookingValue,
    modifyCarBookingFlowQuit
} from 'midoffice/car-bookings/modify/modifyActions';
import {
    getCarModificationFlowBooking,
    getCarModificationFlowBool,
    getCarsModificationDestLoadingBool
} from 'midoffice/car-bookings/selectors';
import Button from 'midoffice/components/Button';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import {injectFormContext} from 'midoffice/newforms/decorators';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import Select from 'midoffice/newforms/widgets/Select';


function preventDefault(e) { e.preventDefault(); }

const getMatchedIdNumbers = (mergedIdNumbers, cdNumbers, idCdMatrix) => {
    for (const {vendor, number} of cdNumbers) {
        const matchedIdNumbers = getCdIdMatchedValue(vendor, number, mergedIdNumbers, cdNumbers, idCdMatrix)?.idNumbers;
        mergedIdNumbers =  matchedIdNumbers || mergedIdNumbers;
    }

    return mergedIdNumbers.map(entity => entity.customIdInput ? {...entity, idNumber: 'custom'} : entity);
};

@injectFormContext
class Editor extends React.Component {
    static propTypes = {
        companyId: PropTypes.number.isRequired,
        disabled: PropTypes.bool.isRequired,
        value: PropTypes.object.isRequired,
        cdChoices: PropTypes.object.isRequired,
        idChoices: PropTypes.object.isRequired,
        itChoices: PropTypes.object.isRequired,
        renderItNumbers: PropTypes.bool.isRequired,
        onChangeDestination: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        primaryGds: PropTypes.oneOf(['amadeus', 'sabre']).isRequired,
        loading: PropTypes.bool,
        carsModifyFlowBooking: PropTypes.object,
        isCarModifyFlowActive: PropTypes.bool,
        negotiatedRatesOptions: PropTypes.array,
        enableCompanyIdField: PropTypes.bool,
        idCdMatrix: PropTypes.array,


        carsDestinationLoaded: PropTypes.func,
        carsDestinationLoading: PropTypes.func,
        onChange: PropTypes.func,
        handleIdCdChange: PropTypes.func,
    };

    state = {
        showCdNumbers: true,
        showIdNumbers: true,
        showItNumbers: true,
        currentId: null,
        currentCd: null,
    }

    componentDidMount() {
        const {value, onChange, idChoices, enableCompanyIdField, idCdMatrix} = this.props;

        const parsedIdNumbers = enableCompanyIdField && Object.keys(idChoices)?.map(vendor => {
            const [idNumber, customIdNumber] = idChoices[vendor][0];

            const customIdInput = idNumber === 'custom' ? customIdNumber : null;
            return dropEmpty({vendor, idNumber, customIdInput});
        });

        const onTheSearchPage = location.pathname.includes('/ui/cars/search');
        const {idNumbers, cdNumbers} = value || {};
        if (enableCompanyIdField && parsedIdNumbers.length && !onTheSearchPage) {
            onChange({value: {...value, idNumbers: getMatchedIdNumbers(
                mergeIdNumbers(parsedIdNumbers, idNumbers),
                cdNumbers,
                idCdMatrix
            )}});
        }
    }

    pickUpAutocomplete = new DestinationAutocomplete('pick_up_autocomplete');
    dropOffAutocomplete = new DestinationAutocomplete('drop_off_autocomplete');

    toggleItNumbers = () => {
        this.setState({showItNumbers: !this.state.showItNumbers});
    }

    toggleCdNumbers = () => {
        this.setState({showCdNumbers: !this.state.showCdNumbers});
    }
    toggleIdNumbers = () => {
        this.setState({showIdNumbers: !this.state.showIdNumbers});
    }

    renderRange(destination, param, primaryGds) {
        const {'distance_units': unit} = settings.USER;
        const choices = getCarsDistanceChoices(unit, destination, primaryGds);
        if (!choices) {
            return null;
        }

        return (
            <div className="ag-destination__wrapper" onClick={preventDefault} >
                <Select.Field
                    name={param}
                    className="ag-destination__range"
                    choices={choices}
                    nowrap />
            </div>
        );

    }

    render() {
        const {
            companyId, disabled, loading, value, onSubmit, renderItNumbers,
            onChangeDestination, primaryGds, cdChoices, itChoices, idChoices,
            carsModifyFlowBooking, isCarModifyFlowActive,
            carsDestinationLoaded, carsDestinationLoading,
            enableCompanyIdField, handleIdCdChange,
        } = this.props;
        const {differentDropOff, pickUp, dropOff} = value;
        const autocompleteExtra = {
            companyId,
            product: 'cars',
            gds: primaryGds,
            applyDebounce: !isCarModifyFlowActive,
        };
        const {showCdNumbers, showIdNumbers, showItNumbers} = this.state;
        const getClassForToggle = (show) => classnames('row__toggle', {
            'row__toggle--expanded': show
        });;

        const booking = parseCarBookingValue(carsModifyFlowBooking || {});
        const pickupQueryMatchFn = getCarsModifyQueryAutocompleteFn(booking?.pickUp?.isAirport, isCarModifyFlowActive);
        const dropOffQueryMatchFn = getCarsModifyQueryAutocompleteFn(booking?.dropOff?.isAirport, isCarModifyFlowActive);

        return (
            <div className="ag-home-form ag-home-form--wide-labels">
                <Checkbox.Field
                    name="differentDropOff"
                    label={gettext('Different Drop-off Location')}
                    wrapperClassName="no-padding"
                    widgetClassName="pull-right text-sm ag-home-form__control--top"
                    plain/>
                <Row>
                    <DestinationSelect.Field
                        label={differentDropOff ? gettext('Pick-up Location') : gettext('Pick-up / Drop-off')}
                        labelSuffix=""
                        inputCol={9}
                        name="pickUp"
                        onChange={onChangeDestination}
                        className="ag-destination form-group--no--bottom"
                        placeholder={gettext('City, airport, landmark or address')}
                        after={this.renderRange(pickUp, 'pickUpDistance', primaryGds)}
                        autocompleteSource={this.pickUpAutocomplete}
                        autocompleteExtra={autocompleteExtra}
                        required={false}
                        disabled={disabled}
                        withCarDestinationInfo
                        carModifyFlowBooking={carsModifyFlowBooking}
                        autoSearch={isCarModifyFlowActive}
                        autoSearchValue={booking?.pickUp?.label}
                        startLoadFn={carsDestinationLoading}
                        finishLoadFn={differentDropOff ? noop : carsDestinationLoaded}
                        queryMatchFn={pickupQueryMatchFn}
                    />
                </Row>
                <Row>
                    {differentDropOff && (
                        <React.Fragment>
                            <DestinationSelect.Field
                                label={gettext('Drop-off Location')}
                                labelSuffix=""
                                inputCol={9}
                                name="dropOff"
                                onChange={onChangeDestination}
                                className="ag-destination"
                                placeholder={gettext('City, airport, landmark or address')}
                                after={this.renderRange(dropOff, 'dropOffDistance', primaryGds)}
                                autocompleteSource={this.dropOffAutocomplete}
                                autocompleteExtra={{...autocompleteExtra, recentSearches: false}}
                                required={false}
                                disabled={disabled}
                                withCarDestinationInfo
                                startLoadFn={noop}
                                autoSearch={isCarModifyFlowActive}
                                autoSearchValue={booking?.dropOff?.label}
                                finishLoadFn={carsDestinationLoaded}
                                queryMatchFn={dropOffQueryMatchFn}
                            />

                            <hr className="ag-home-form__hr"/>
                        </React.Fragment>
                    )}
                </Row>
                <Row>
                    <CheckinRange.Field
                        name="dates"
                        label={gettext('Date / Time')}
                        minTimeLabel={gettext('Pick-Up time:')}
                        maxTimeLabel={gettext('Drop-Off time:')}
                        defaultTime="10:00"
                        is12hTime={settings.USER.date_format === 'en'}
                        required={false}
                        inputCol={9}
                        withTime
                        plain/>
                </Row>

                <hr className="ag-home-form__hr"/>

                <Row>
                    <AcrissField
                        name="acriss"
                        inputCol={9}
                        required={false}
                        label={gettext('ACRISS/SIPP Code')}
                        placeholder={gettext('ACRISS/SIPP/Pseudo Code')}/>
                </Row>

                <hr className="ag-home-form__hr"/>

                <div className={getClassForToggle(showCdNumbers)}>
                    <div className="row__toggle__label" data-test="cd-collapse" onClick={this.toggleCdNumbers}>
                        <span className="glyphicons glyphicons-chevron-right"/>
                        {gettext('CD Number(s)')}
                    </div>

                    {showCdNumbers && <div className="row__toggle__content">
                        {enableCompanyIdField && <div className="alert-warning" style={{marginBottom: 15, marginLeft: 10, marginRight: 10}}>
                            {gettext('Changes in СD numbers may affect the ID numbers. Please ensure that the selected ID number is correct.')}
                        </div>}

                        <NegotiatedNumbers.Field
                            multi
                            plainWidget
                            name="cdNumbers"
                            addMoreLabel={gettext('+ Add the CD Number')}
                            inputPlaceholder={gettext('CD Number')}
                            className="form-group--well"
                            disabled={disabled}
                            addMoreComponent={AddMoreNegotiatedNumber}
                            vendors={getVendorsChoices(primaryGds)}
                            choices={cdChoices}
                            handleIdCdChange={handleIdCdChange}
                        />
                    </div>}
                </div>

                <div className={getClassForToggle(showIdNumbers)}>
                    <div className="row__toggle__label" data-test="id-collapse" onClick={this.toggleIdNumbers}>
                        <span className="glyphicons glyphicons-chevron-right"/>
                        {gettext('ID Number(s)')}
                    </div>

                    {showIdNumbers && <div className="row__toggle__content">
                        {/* <div className="row__toggle__message">
                            <span>Not available in the multi-pnr mode.</span>
                        </div>  */}
                        {enableCompanyIdField ? (<NegotiatedIdNumbers.Field
                            multi
                            plainWidget
                            name="idNumbers"
                            addMoreComponent={AddMoreIDNumber}
                            addMoreLabel={gettext('+ Add the ID Number')}
                            inputPlaceholder={gettext('ID Number')}
                            className="form-group--well"
                            disabled={disabled}
                            vendors={getVendorsChoices(primaryGds)}
                            choices={idChoices}
                            cdNumbers={value.cdNumbers}
                            customCd={value.cdNumbers[0]?.number === 'custom'}
                            idCdMatrix={this.props.idCdMatrix}
                            handleIdCdChange={handleIdCdChange}
                        />) : (
                            <IdNumbers.Field multi
                                plainWidget
                                addMoreComponent={AddMore}
                                addMoreLabel={gettext('+ Add the ID Number')}
                                name="idNumbers"
                                inputPlaceholder={gettext('ID Number')}
                                className="form-group--well"
                                disabled={disabled}
                                vendors={getVendorsChoices(primaryGds)} />
                        )}
                    </div>}
                </div>

                {renderItNumbers && <div className={getClassForToggle(showItNumbers)}>
                    <div className="row__toggle__label" data-test="it-collapse" onClick={this.toggleItNumbers}>
                        <span className="glyphicons glyphicons-chevron-right"/>
                        {gettext('IT Number(s)')}
                    </div>

                    {showItNumbers && <div className="row__toggle__content">
                        <NegotiatedNumbers.Field multi
                            plainWidget
                            name="itNumbers"
                            addMoreLabel={gettext('+ Add the IT Number')}
                            inputPlaceholder={gettext('IT Number')}
                            className="form-group--well"
                            disabled={disabled}
                            addMoreComponent={AddMoreNegotiatedNumber}
                            vendors={getVendorsChoices(primaryGds)}
                            choices={itChoices}/>
                    </div>}
                </div>}

                <div className="ag-home-form__control ag-home-form__control--bottom">
                    <Button className="pull-right"
                        bsStyle="success"
                        onClick={onSubmit}
                        progress={loading}
                        disabled={disabled || loading}
                        data-testid="find-car-search-btn"
                    >
                        <Shortcut label={gettext('Search')} shortcut={SHORTCUTS_LIST.G} action={onSubmit}
                            hideShortcut={disabled}/>
                    </Button>
                </div>
            </div>
        );
    }
}


function mapStateProps(state) {
    const {value, errors} = getDestForm(state);
    const {pnr} = getPnrProfile(state, 0);
    const options = getOptions(state);
    const carsModifyFlowBooking = getCarModificationFlowBooking(state);
    const negotiatedRatesOptions = options['cars_negotiated_rates'];

    return {
        value, errors,
        pnr,
        enableCompanyIdField: getFeatureFlag(state, 'ENABLE_COMPANY_ID_FIELD'),
        primaryGds: options['primary_gds'],
        idChoices: getNegotiatedIdChoices(negotiatedRatesOptions, value.idNumbers),
        cdChoices: getNegotiatedChoices(options, 'cars_negotiated_rates'),
        itChoices: getNegotiatedChoices(options, 'cars_it_numbers'),
        idCdMatrix: getNegotiatedIdCdMatrix(negotiatedRatesOptions),
        renderItNumbers: options['cars_home_page']['render_it_numbers'],
        companyId: getSelectedConfiguration(state) || currentCompany(state) || carsModifyFlowBooking?.['configuration_id'],
        loading: isRatesLoading(state),
        companySmid: getCompanySmid(state),
        isCarModifyFlowActive: getCarModificationFlowBool(state),
        carsModifyFlowDestLoading: getCarsModificationDestLoadingBool(state),
        carsIdNumbers: getCarsIdNumbers(state),
        carsModifyFlowBooking,
    };
}


@connect(mapStateProps, {
    onChange: changeDestination,
    onChangeDestination: reuseDestination,
    onSearch: searchCars,
    carsDestinationLoading: carsDestinationLoading,
    carsDestinationLoaded: carsDestinationLoaded,
    modifyCarBookingFlowQuit,
})
export default class CarsDestinationSearch extends React.Component {
    static propTypes = {
        value: PropTypes.object.isRequired,
        editable: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,
        pnr: PropTypes.string,
        primaryGds: PropTypes.oneOf(['amadeus', 'sabre']).isRequired,
        companyId: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onChangeDestination: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
        companySmid: PropTypes.string,
        isCarModifyFlowActive: PropTypes.bool,
        carsModifyFlowDestLoading: PropTypes.bool,
        enableCompanyIdField: PropTypes.bool,

        carsModifyFlowBooking: PropTypes.object,
        negotiatedRatesOptions: PropTypes.array,
        carsIdNumbers: PropTypes.array,
        idCdMatrix: PropTypes.array,

        carsDestinationLoaded: PropTypes.func,
        carsDestinationLoading: PropTypes.func,

        modifyCarBookingFlowQuit: PropTypes.func,
    };
    componentDidMount() {
        const {modifyCarBookingFlowQuit} = this.props;
        window.addEventListener('popstate', function (event) {
            if (event?.target?.location?.pathname === '/bookings/cars/') {
                modifyCarBookingFlowQuit();
            }
        });
    }

    handleIdCdChange = (vendor, number, isCdUpdate = false) => {
        const {value, onChange, idCdMatrix} = this.props;
        const {cdNumbers, idNumbers} = value;

        const cdIdMatchedValues = getCdIdMatchedValue(vendor, number, idNumbers, cdNumbers, idCdMatrix, isCdUpdate);
        if (cdIdMatchedValues) {
            onChange({
                value: {
                    ...value,
                    ...cdIdMatchedValues,
                },
            });
        }
    };

    handleSubmit = ()=> {
        const {value, primaryGds, onChange, onSearch, companySmid, enableCompanyIdField} = this.props;
        const schema = getCarsSearchSchema(enableCompanyIdField);
        const errors = schema.validate(value, {strict: true, gds: primaryGds});
        const {differentDropOff, dropOff, pickUp} = value;
        const {label: destination} = pickUp || {label: null};
        if (differentDropOff && dropOff && (dropOff.label === '' || !dropOff.label)) {
            onChange({value: {...value, differentDropOff: false}, errors});
        }

        if (noErrors(errors)) {
            setBroadStreetTargets({
                product: PRODUCT_TYPES.CAR,
                smid: companySmid,
                destination,
            });
            onSearch();
        }
        else {
            onChange({value, errors});
        }
    };

    renderPNR(pnr) {
        pnr = pnr || gettext('Not selected');

        return <span>&nbsp;<strong>{pnr}</strong></span>;
    }

    render() {
        const {editable, pnr, primaryGds, loading, carsModifyFlowDestLoading, enableCompanyIdField} = this.props;
        const disabled = carsModifyFlowDestLoading || !editable;
        const isLoading = carsModifyFlowDestLoading || loading;
        const schema = getCarsSearchSchema(enableCompanyIdField);

        return (
            <div>
                <h4 className="block-title">
                    {gettext('Add car segment to your PNR:')}
                    {editable && this.renderPNR(pnr)}
                </h4>
                <Editor {...this.props}
                    disabled={disabled}
                    loading={isLoading}
                    schema={schema}
                    schemaParams={{gds: primaryGds}}
                    primaryGds={primaryGds}
                    onChangeDestination={this.props.onChangeDestination}
                    onSubmit={this.handleSubmit}
                    handleIdCdChange={this.handleIdCdChange}
                />
            </div>
        );
    }
}
