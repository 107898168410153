import noop from 'lodash/noop';
import React from 'react';
import PropTypes from 'prop-types';

import Select from 'midoffice/newforms/widgets/Select';
import MaskInput from 'midoffice/newforms/widgets/MaskInput';

import {injectField} from 'midoffice/newforms/decorators';
import {getPhoneCodeChoices, getPhoneCodeByCountryCode} from 'airborne/air/checkout/helpers/phoneCodes';


@injectField
export default class InternationalPhoneNumber extends React.Component {
    static propTypes = {

        className: PropTypes.string,
        disabled: PropTypes.bool,
        inputSize: PropTypes.string,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        readOnly: PropTypes.bool,
        value: PropTypes.any,

        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        onFocus: PropTypes.func,
        inputStyle: PropTypes.object,
    };

    static defaultProps = {
        className: 'form-control',
        inputSize: 'medium',
        disabled: false,
        readOnly: false,
        onBlur: noop,
        onFocus: noop,
        value: {},
    };

    handleCountryChange = (newValue) => {
        const {onChange, value, name} = this.props;
        const phoneCodeByCountryCode = getPhoneCodeByCountryCode();
        let oldValue = value && value['country_code'] || null;
        let number = value && value.number || '';
        if (newValue !== oldValue) {
            let oldCode = phoneCodeByCountryCode[oldValue];
            let newCode = phoneCodeByCountryCode[newValue] || '';
            number = number.startsWith(oldCode)
                ? newCode + number.substring(oldCode.length)
                : number;
        }
        onChange({
            ...value,
            'country_code': newValue,
            number: number.trim(),
        }, name);

    }

    handlePhoneChange = (number) => {
        const {value, name, onChange} = this.props;
        onChange({
            ...value,
            number: number.trim(),
        }, name);
    }

    render() {
        const {value, inputStyle, disabled} = this.props;
        const phoneCodeByCountryCode = getPhoneCodeByCountryCode();
        return (<div className="compound-inputs">
            <div className="compound-inputs__col" style={{width: '40%'}}>
                <div className="input-max custom-select">
                    <Select
                        name="country_code"
                        disabled={disabled}
                        allowEmpty
                        value={value['country_code']}
                        choices={getPhoneCodeChoices()}
                        onChange={this.handleCountryChange} />
                </div>
            </div>
            <div className="compound-inputs__col">
                <MaskInput
                    name="number"
                    value={value.number || ''}
                    style={inputStyle}
                    mask={`${phoneCodeByCountryCode[value.country_code] || ''}ddddddddddddddd`}
                    formatChars={{
                        'd': '[0-9]',
                    }}
                    maskChar=""
                    disabled={disabled || !(value && value['country_code'])}
                    onChange={this.handlePhoneChange} />
            </div>
        </div>);
    }
}
