// copied from airborne/homepage2/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import Row from 'react-bootstrap/Row';
import omit from 'lodash/omit';

export default class SectionRow extends Component {

    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.node,
        open: PropTypes.bool,
        name: PropTypes.string.isRequired,
        handleToggle: PropTypes.func.isRequired,
    }

    handleOpen = () => {
        const {name, open, handleToggle} = this.props;

        handleToggle(name, !open);
    }

    render() {
        const {children, title, open, ...props} = this.props;
        const rowClasses = classname(
            'row__toggle',
            {'row__toggle--expanded': open},
        );

        return (<Row className={rowClasses} {...omit(props, 'handleToggle')}>
            <div
                className="row__toggle__label"
                onClick={this.handleOpen}
            >
                <span className="glyphicons glyphicons-chevron-right" />
                {title}
            </div>
            {open &&
                <div className="row__toggle__content">
                    {children}
                </div>
            }
        </Row>);
    }
}
