import {isSameSeats} from 'airborne/air/checkout/helpers/seatSelection';
import {buildSelectionSeat} from 'airborne/air/checkout/helpers/seatSelection';
import isNil from "lodash/isNil";

export const getSeatSelection = state => state.airBooking.seatSelection;

export const getSelectedSeatsByTicketIndex = (state, ticketIndex) => {
    const seatSelection = getSeatSelection(state);

    return seatSelection.filter(seat => seat.ticketIndex === ticketIndex);
};

export const getSelectedSeatsBySegmentId = (state, segmentIdRef, ticketIndex) => {
    const seatSelection = isNil(ticketIndex) ? getSeatSelection(state) : getSelectedSeatsByTicketIndex(state, ticketIndex);

    return seatSelection.filter(seat => seat.segmentIdRef === segmentIdRef);
};

export const isSeatSelected = (state, seat, segmentRefId) => {
    const seatSelection = getSeatSelection(state);
    const incomeSeat = buildSelectionSeat(seat, segmentRefId);

    return Boolean(seatSelection.find(currentSeat => isSameSeats(currentSeat, incomeSeat)));
};

export const getSeatSelectionPrice = (state, ticketIndex) => {
    const seatSelection = isNil(ticketIndex) ? getSeatSelection(state) : getSelectedSeatsByTicketIndex(state, ticketIndex);
    return seatSelection.reduce((sum, {amount}) => isNaN(amount) ? sum : Number(amount) + sum, 0);
};
