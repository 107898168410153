import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import gettext from 'airborne/gettext';

import DestinationSelect from './DestinationSelect';
import {AIR_TRIP_TYPES} from '../types';

export default class AirTransferAirports extends React.Component {
    static propTypes = {
        autocompleteSource: PropTypes.object.isRequired,
        autocompleteExtra: PropTypes.object.isRequired,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        multiCount: PropTypes.number,
        tripType: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.object),
    }

    getNumberOfFields() {
        const {tripType, multiCount} = this.props;

        switch (tripType) {
            case AIR_TRIP_TYPES.ONE_WAY:
                return 1;
            case AIR_TRIP_TYPES.ROUND_TRIP:
                return 2;
            case AIR_TRIP_TYPES.MULTI_CITY:
                return multiCount;
            default:
                return 0;
        }
    }

    getLabels() {
        const {tripType, multiCount} = this.props;

        if (tripType === AIR_TRIP_TYPES.MULTI_CITY) {
            return range(multiCount).map(index => (
                gettext('Route {i}', {i: index + 1})
            ));
        }

        return [
            gettext('Outbound'),
            gettext('Inbound'),
        ];
    }

    getPlaceholders() {
        const {tripType, multiCount} = this.props;

        if (tripType === AIR_TRIP_TYPES.MULTI_CITY) {
            return range(multiCount).map(index => (
                gettext('Add transfer airports for Route {i}', {i: index + 1})
            ));
        }

        return [
            gettext('Add transfer airports for Outbound route'),
            gettext('Add transfer airports for Inbound route'),
        ];
    }

    getValue() {
        const {value} = this.props;

        return range(this.getNumberOfFields()).map((_, index) =>
            value?.[index] || {}
        );
    }

    render() {
        const {
            autocompleteSource,
            autocompleteExtra,
            disabled,
            required,
        } = this.props;


        return (
            <DestinationSelect.Field
                value={this.getValue()}
                name="connections"
                autocompleteSource={autocompleteSource}
                autocompleteExtra={autocompleteExtra}
                required={required}
                disabled={disabled}
                className="ag-destination"
                inputCol={9}
                multiLabels={this.getLabels()}
                multiPlaceholders={this.getPlaceholders()}
                withAirDestinationInfo
                multi
                noAddMore
            />
        );
    }
}
