import findLastIndex from 'lodash/findLastIndex';
import isNil from "lodash/isNil";
import * as types from '../actionTypes';
import {buildSelectionSeat} from 'airborne/air/checkout/helpers/seatSelection';
import {isSameSeats} from 'airborne/air/checkout/helpers/seatSelection';
import {getSeatSelection, getSelectedSeatsBySegmentId} from 'airborne/store/modules/checkout_air/selectors';

const addSeatToSelection = seat => ({
    type: types.AIR_ADD_SEAT_TO_SEAT_SELECTION,
    seat,
});

const removeSeatFromSelection = seat => ({
    type: types.AIR_REMOVE_SEAT_FROM_SELECTION,
    seat,
});

const replaceLastSeatInSelection = (seat, index) => ({
    type: types.AIR_REPLACE_LAST_SEAT_IN_SELECTION,
    seat,
    index,
});

export const toggleSeatInSelection = (seat, segmentIdRef, ticketIndex) => (dispatch, getState) => {
    const state = getState();
    const selectedSeats = getSeatSelection(state);
    const selectedSegmentSeats = getSelectedSeatsBySegmentId(state, segmentIdRef, ticketIndex);
    const newSeat = buildSelectionSeat(seat, segmentIdRef, ticketIndex);

    // Hardcoded to one seat max
    const travelersCount = 1;

    if (selectedSeats.find(currentSeat => isSameSeats(currentSeat, newSeat))) {
        return dispatch(removeSeatFromSelection(newSeat));
    }

    if (selectedSegmentSeats.length >= travelersCount) {
        const seatIndex = findLastIndex(
            selectedSeats,
            seat => {
                const isSameSegment = seat.segmentIdRef === segmentIdRef;
                return !isNil(ticketIndex)
                    ? seat.ticketIndex === ticketIndex && isSameSegment
                    : isSameSegment;
            }
        );
        return dispatch(replaceLastSeatInSelection(newSeat, seatIndex));
    }

    return dispatch(addSeatToSelection(newSeat));
};

export const clearSeatSelection = (segmentIdRef, ticketIndex) => ({
    type: types.AIR_CLEAR_SEAT_SELECTION,
    segmentIdRef,
    ticketIndex,
});

export const clearTicketSeatSelection = (ticketIndex) => ({
    type: types.AIR_CLEAR_TICKET_SEAT_SELECTION,
    ticketIndex,
});

export const clearAllSeatSelection = () => ({
    type: types.AIR_CLEAR_ALL_SEAT_SELECTION,
});
