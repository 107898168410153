import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import settings from 'airborne/settings';
import gettext from 'airborne/gettext';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'midoffice/components/Button';

import CheckinRange from 'airborne/homepage2/CheckinRange';
import DestinationSelect from 'airborne/homepage2/DestinationSelect';
import ChainsField from 'airborne/homepage2/ChainsField';
import Input from 'midoffice/newforms/widgets/Input';
import Select from 'midoffice/newforms/widgets/Select';
import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';

import {getAllPnrs, getCompanySmid} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {getDistanceChoices} from 'airborne/homepage2/helpers/searchDistance';
import schema from 'airborne/homepage2/DestinationSearchSchema';
import HotelDestinationAutocomplete from 'airborne/homepage2/autocomplete/HotelDestinationAutocomplete';
import {injectFormContext} from 'midoffice/newforms/decorators';
import {noErrors} from 'midoffice/newforms/helpers';
import {getDestForm, getOptions} from 'airborne/store/modules/homepage/selectors/homepage';
import {isCloseCheckin} from 'airborne/homepage2/helpers/dateLimits';

import searchHotels from 'airborne/store/modules/search_hotels/actions/lazySearch';
import {changeDestination, reuseDestination} from '../store/modules/homepage/actions';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';
import {setBroadStreetTargets, PRODUCT_TYPES} from 'airborne/helpers/setBroadStreetTargets';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {currentCompany} from './helpers/search';


import {UnsafeSearchWarning} from 'airborne/homepage2/UnsafeSearchWarning';
import {CloseCheckinWarning} from 'airborne/homepage2/CloseCheckingWarning';

function preventDefault(event) {
    event.preventDefault();
}


@injectFormContext
class DestinationEditor extends React.Component {
    static propTypes = {
        results: PropTypes.bool,
        errors: PropTypes.object,
        chainOptions: PropTypes.object,
        rateOptions: PropTypes.array,
        value: PropTypes.shape({
            distance: PropTypes.number,
        }),
        companyId: PropTypes.number,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        onSubmit: PropTypes.func.isRequired,
        onChangeDestination: PropTypes.func.isRequired,
        accommodationsExcluded: PropTypes.bool,
    };

    destAutocomplete = new HotelDestinationAutocomplete();

    getGuestChoices() {
        return [
            [1, gettext('1 room, 1 guest')],
            [2, gettext('1 room, 2 guests')],
            [3, gettext('1 room, 3 guests')],
            [4, gettext('1 room, 4 guests')],
        ];
    }

    renderRange(destination, distance) {
        const {'distance_units': unit} = settings.USER;
        const choices = getDistanceChoices(unit, destination, distance);
        if (!choices) {
            return null;
        }
        return (<div className="ag-destination__wrapper"
            onClick={preventDefault} >
            <Select.Field
                name="distance"
                className="ag-destination__range"
                choices={getDistanceChoices(unit, destination, distance)}
                nowrap />
        </div>);
    }

    renderWarningBlock = () => (
        <>
            <strong>{gettext('Selected checkin is very close or in the past.')}</strong>
            <div>{gettext('Please verify hotel\'s local date and time before confirming the booking.')}</div>
        </>
    )

    renderCloseCheckinSearchWarning() {
        const {value: {dates}} = this.props;
        if (!isCloseCheckin(dates)) {
            return null;
        }

        return (<Row className="form-warning__wrapper">
            <Col xs={12}>
                <div className="form-warning search-warning">
                    {this.renderWarningBlock()}
                </div>
            </Col>
        </Row>);
    }

    renderHome() {
        const {
            companyId,
            chainOptions,
            rateOptions,
            value: {destination, distance, dates},
            loading,
            disabled,
            accommodationsExcluded,
        } = this.props;

        return (<div className="ag-home-form">
            <Row>
                <DestinationSelect.Field
                    label={gettext('Going to')}
                    labelSuffix=""
                    name="destination"
                    className="ag-destination js-hotel-destination-autocompletion"
                    after={this.renderRange(destination, distance)}
                    placeholder={gettext('City, airport, landmark, hotel name or address')}
                    autocompleteSource={this.destAutocomplete}
                    autocompleteExtra={{companyId, product: 'hotels'}}
                    onChange={this.props.onChangeDestination}
                    required={false}
                    disabled={disabled}
                    withDestinationInfo />
            </Row>
            <Row>
                <CheckinRange.Field
                    label={gettext('Dates')}
                    labelSuffix=""
                    name="dates"
                    className="ag-home-daterange"
                    required={false}
                    disabled={disabled}
                    plain />
            </Row>
            <UnsafeSearchWarning dates={dates} />
            <CloseCheckinWarning dates={dates} />
            <Row>
                <Select.Field
                    label={gettext('Guests')}
                    labelSuffix=""
                    name="guests"
                    className="ag-home-guests"
                    choices={this.getGuestChoices()}
                    disabled={disabled} />
            </Row>

            <div className="ag-home-form__extra">
                {rateOptions && (rateOptions.length > 0) && (<Row>
                    <MultiCheckbox.Field
                        label={gettext('Special Rate')}
                        labelSuffix=""
                        name="special_rates"
                        choices={rateOptions}
                        table={false}
                        inputCol={10}
                        disabled={disabled}
                        allowEmpty />
                </Row>)}
                {chainOptions && (<Row>
                    <ChainsField.Field
                        label={gettext('Hotel Chain')}
                        labelSuffix=""
                        placeholder={gettext('Hotel chain(s)')}
                        choices={chainOptions}
                        name="chains"
                        inputCol={10}
                        disabled={disabled} />
                </Row>)}
                <Row>
                    <Input.Field
                        label={gettext('Hotel Name Contains')}
                        labelSuffix=""
                        placeholder={gettext('Keyword')}
                        name="hotel_name"
                        inputCol={10}
                        disabled={disabled} />
                </Row>
                {!accommodationsExcluded &&
                    <>
                        <Row>
                            <div className="form-group__label-offset">
                                <Checkbox.Field
                                    inputCol={8}
                                    name="exclude_non_traditional"
                                    label={gettext('Exclude non-traditional accommodations')}
                                    helpText={gettext('This would exclude property types such as homestays, houseboats, campsites, love hotels etc.')}
                                    helpTooltip
                                />
                            </div>
                        </Row>
                    </>
                }
            </div>

            <div className="ag-home-form__control ag-home-form__control--bottom">
                <Button
                    className="js-find-button pull-right"
                    progress={loading}
                    disabled={loading}
                    onClick={this.props.onSubmit}
                    bsStyle="success"
                    data-testid="find-hotels-search-btn"
                >
                    <Shortcut label={gettext('Search')} shortcut={SHORTCUTS_LIST.G} action={this.props.onSubmit} hideShortcut={disabled} />
                </Button>
            </div>
        </div>);
    }

    renderSearch() {
        const {
            companyId,
            chainOptions,
            rateOptions,
            value: {destination, distance},
            loading,
            disabled,
            accommodationsExcluded,
        } = this.props;

        return (<div className="search-bar clearfix">
            <div className="container">
                {this.renderCloseCheckinSearchWarning()}
                <Row>
                    <DestinationSelect.Field
                        name="destination"
                        className="sb-destination"
                        after={this.renderRange(destination, distance)}
                        placeholder={gettext('City, airport, landmark, hotel name or address')}
                        autocompleteSource={this.destAutocomplete}
                        autocompleteExtra={{companyId, product: 'hotels'}}
                        onChange={this.props.onChangeDestination}
                        required={false}
                        inputCol={12}
                        disabled={disabled}
                        withDestinationInfo />
                    <CheckinRange.Field
                        name="dates"
                        className="sb-dates"
                        required={false}
                        inputCol={12}
                        disabled={disabled}
                        plain />
                    <Select.Field
                        name="guests"
                        className="sb-guests"
                        choices={this.getGuestChoices()}
                        inputCol={12}
                        disabled={disabled} />
                    <div className="form-group sb-control">
                        <Col xs={12}>
                            <Button
                                progress={loading}
                                disabled={loading}
                                onClick={this.props.onSubmit}
                                className="btn-block"
                                variant="info"
                            >
                                <Shortcut label={gettext('Search')} shortcut={SHORTCUTS_LIST.S} action={this.props.onSubmit} />
                            </Button>
                        </Col>
                    </div>
                    <div className="search-bar__options">
                        <div className="search-bar__options__row">
                            {rateOptions && (rateOptions.length > 0) && (<MultiCheckbox.Field
                                label={gettext('Special Rate')}
                                labelSuffix=""
                                name="special_rates"
                                choices={rateOptions}
                                grid={false}
                                table={false}
                                disabled={disabled}
                                allowEmpty />)}
                            {chainOptions && (<ChainsField.Field
                                label={gettext('Hotel Chain')}
                                labelSuffix=""
                                placeholder={gettext('Hotel chain(s)')}
                                choices={chainOptions}
                                name="chains"
                                grid={false}
                                disabled={disabled} />)}
                            <Input.Field
                                label={gettext('Hotel Name Contains')}
                                labelSuffix=""
                                placeholder={gettext('Keyword')}
                                name="hotel_name"
                                grid={false}
                                disabled={disabled} />
                        </div>
                        {!accommodationsExcluded && <>
                            <Checkbox.Field
                                inputCol={12}
                                name="exclude_non_traditional"
                                label={gettext('Exclude non-traditional accommodations')}
                                helpText={gettext('This would exclude property types such as homestays, houseboats, campsites, love hotels etc.')}
                                helpTooltip
                            />
                        </>
                        }
                    </div>
                </Row>
            </div>
        </div>);
    }

    render() {
        const {results} = this.props;
        return results ? this.renderSearch()
            : this.renderHome();
    }
}

function mapStateProps(state) {
    const {dest, hotels} = state;
    const {value, errors} = getDestForm(state);
    const options = getOptions(state);
    const chainOptions = options.chains;
    const rateOptions = (options['special_rates'] || [])
        .map(([value, label])=> ([value, label]));

    return {
        value,
        errors,
        chainOptions,
        rateOptions,
        loading: Boolean(
            dest.loading || hotels.hotels.loading || Object.keys(hotels.avail.loading).length
        ),
        companyId: getSelectedConfiguration(state) || currentCompany(state),
        companySmid: getCompanySmid(state),
        pnrs: getAllPnrs(state),
        accommodationsExcluded: options['non_traditional_accommodations_excluded'],
    };
}

@connect(mapStateProps, {
    onSearch: searchHotels,
    onChange: changeDestination,
    onChangeDestination: reuseDestination,
})
export default class Form extends React.Component {
    static propTypes = {
        value: PropTypes.object,
        errors: PropTypes.object,
        pnrs: PropTypes.arrayOf(PropTypes.string).isRequired,
        editable: PropTypes.bool,
        results: PropTypes.bool,
        onSearch: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        onChangeDestination: PropTypes.func.isRequired,
        accommodationsExcluded: PropTypes.bool,
        companySmid: PropTypes.string,
    };

    handleSubmit = () => {
        const {value, companySmid} = this.props;
        const errors = schema.validate(value, {strict: true});
        const {destination: dest} = value;
        const {label: destination} = dest || {label: null};
        if (noErrors(errors)) {
            setBroadStreetTargets({
                product: PRODUCT_TYPES.HOTEL,
                smid: companySmid,
                destination,
            });

            this.props.onSearch();
        }
        else {
            this.props.onChange({value, errors});
        }
    };

    renderPNR() {
        const {pnrs} = this.props;
        return (<span>
            :&nbsp;<strong>{pnrs.join(', ') || gettext('Not selected')}</strong>
        </span>);
    }

    renderTitle(editable) {
        return (<h4 className="block-title">
            {gettext('Add hotel segment to your PNR')}
            {editable && this.renderPNR()}
        </h4>);
    }

    render() {
        const {editable, results} = this.props;
        return (<div>
            {!results && this.renderTitle(editable)}
            <DestinationEditor {...this.props}
                disabled={!editable}
                schema={schema}
                onSubmit={this.handleSubmit} />
        </div>);
    }
}
