/* global RELEASE,global,process */
import {extraErrorDataIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import pick from 'lodash/pick';
import Raven from 'raven-js';

import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

const PREDICATES = {
    creditCard: /\d{12,19}/,
};
function processErrorMessage(message) {
    return Object.values(PREDICATES).reduce((message, regex) => {
        return message && message.replace(regex, '***');
    }, message);
}
function processException(report) {
    if (!report.exception) {
        return;
    }

    report.exception.values = (report.exception.values || []).map(row => {
        if (!row.value) {
            return row;
        }
        row.value = processErrorMessage(row.value);
        return row;
    });
}
export function sentryReport(report) {
    report.message = processErrorMessage(report.message);
    processException(report);
    report.breadcrumbs.values = (report.breadcrumbs.values || []).map(row => {
        if (!row || !row.message) {
            return row;
        }
        row.message = processErrorMessage(row.message);
        return row;
    });
    return report;
}

class SentryAdapter {
    constructor() {
        this.contextFields = ['is_staff', 'is_superuser', 'email'];
    }

    captureException(exception) {
        if (this.lib) {
            this.lib.captureException(exception);
        }
    }

    captureMessage(message) {
        if (this.lib) {
            this.lib.captureMessage(message);
        }
    }

    _initRaven(settings) {
        const url = settings.RAVEN_URL.replace(/^https?:/, '');
        Raven.config(url, {release: RELEASE, maxUrlLength: Infinity, debug: false});
        Raven.setDataCallback((data, original) => sentryReport(original ? original(data) : data));
        Raven.setUserContext(pick(settings.USER, ...this.contextFields));
        Raven.install();
        global.onunhandledrejection = Raven._promiseRejectionHandler; // eslint-disable-line immutable/no-mutation
        this.lib = Raven;
    }

    _initSentry(settings) {
        Sentry.init({
            dsn: settings.RAVEN_URL,
            release: RELEASE,
            maxUrlLength: Infinity,
            debug: false,
            integrations: [
                extraErrorDataIntegration(),
                Sentry.reactRouterV5BrowserTracingIntegration({
                    history,
                }),
            ],
            ignoreErrors: [
                /Blocked\s'([^']+)'\sfrom\s'([^']+)'/
            ],
        });
        this.lib = {
            captureException: Sentry.captureException,
            captureMessage: Sentry.captureMessage,
        };
        Sentry.setUser(pick(settings.USER, ...this.contextFields));
    }
    init(settings) {
        if (!settings.RAVEN_URL || process.env.NODE_ENV === 'development') {
            return null;
        }
        const NEW_SENTRY_ENABLED = getFeatureFlag(null, 'NEW_SENTRY_FE_ENABLED');
        if (settings.USER) {
            this.contextFields.push('first_name', 'last_name', 'middle_name');
        }
        if (NEW_SENTRY_ENABLED) {
            this._initSentry(settings);
        }
        else {
            this._initRaven(settings);
        }
    }
}
const adapter = new SentryAdapter();
export default adapter;
