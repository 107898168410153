import {gettext} from "airborne/gettext";
import {getOptions} from "airborne/store/modules/homepage/selectors/homepage";
import {getCarModificationFlowBool} from "midoffice/car-bookings/selectors";
import isEmpty from "lodash/isEmpty";
import {getPnrProfile, isModification, isMultiPnr} from "airborne/store/modules/homepage/selectors/pnrProfile";

const MODIFICATION_FLOW_DISABLE_HINT = gettext('Not available in PNR modification mode');
export function getHotelsDisabledReason(state) {
    const options = getOptions(state);
    const carModificationFlow = getCarModificationFlowBool(state);

    if (carModificationFlow) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['hotel_content_enabled'] === false) {
        return gettext('All content disabled');
    }

    return null;
}

export function getCarsDisabledReason(state) {
    const options = getOptions(state);
    const activeModificationMode = isModification(state);

    if (activeModificationMode) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }


    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_cars_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    if (isMultiPnr(state)) {
        return gettext('Only single PNR');
    }

    if (options['is_cars_available_in_primary_gds'] === false) {
        return gettext('Not available in company\'s Primary GDS');
    }

    return null;
}

export function getAirDisabledReason(state) {
    const options = getOptions(state);
    const pnrProfile = getPnrProfile(state, 0);
    const carModificationFlow = getCarModificationFlowBool(state) || null;
    const activeModificationMode = isModification(state);

    if (carModificationFlow || activeModificationMode) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_air_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    if (isMultiPnr(state)) {
        return gettext('Only single PNR');
    }

    if (!pnrProfile || isEmpty(pnrProfile)) {
        return null;
    }

    const pnrTravelers = pnrProfile['pnr_travelers'];

    if (pnrTravelers && pnrTravelers.length > 1) {
        return gettext('Only Single-PAX PNRs');
    }

    return null;
}

export function getRailDisabledReason(state) {
    const options = getOptions(state);

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_rail_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    return null;
}
