import air from 'airborne/store/modules/search_hotels/actions/air';
import omit from 'lodash/omit';

import {parse} from 'airborne/types';
import {currentCompany} from 'airborne/homepage2/helpers/search';

import {getRate} from 'airborne/store/modules/search_cars/selectors';

import {apiError} from 'airborne/cars/search/helpers/apiError';
import {showModal} from 'airborne/store/modules/header/actions/modal';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';


export function showError(message=null, kind='error') {
    return showModal(kind, {message});
};


export function getRateDetails(rateId, rethrow=false) {
    const URL = '/cars/get_rate_details/';
    return async function getRateDetailsD(dispatch, getState) {
        const {detailsLoaded, detailsLoading, rateKey} = getRate(getState(), rateId);
        if (detailsLoaded || detailsLoading) {
            return true;
        }
        const state = getState();
        const params = {
            'rate_key': rateKey,
            'configuration_id': getSelectedConfiguration(state) || currentCompany(state),
        };
        dispatch({type: 'CARS_RATE_DETAILS_LOADING', rateId});

        try {
            const raw = await air('GET', URL, params);
            const data = parse('carsRateDetails', raw.rate);
            let parsedData = data;
            parsedData.totalAfterTaxes = data.total;
            dispatch({type: 'CARS_RATE_DETAILS_LOADED', data: omit(parsedData, 'total'), rateId});
            return parsedData;
        }
        catch (response) {
            const details = apiError(response);
            dispatch(showError(details));
            dispatch({type: 'CARS_RATE_DETAILS_FAIL', rateId});
            if (rethrow) throw response;
        }

    };
}

export default getRateDetails;
