import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import {connect} from 'react-redux';
import Schema from './schema';
import {getCollapsableSections, getDestForm, getAirSearchType} from 'airborne/store/modules/homepage/selectors/homepage';
import {changeDestination, reuseDestination, toggleCollapsableSection} from 'airborne/store/modules/homepage/actions';
import {getCompanySmid, getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getPnrForm} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {searchAir} from 'airborne/store/modules/search_air/actions/searchAir';
import {isAvailabilityLoading} from 'airborne/store/modules/availability_air/selectors';
import {requestAvailability} from 'airborne/store/modules/availability_air/actions';
import {noErrors} from 'midoffice/newforms/helpers';
import {isFaresLoading} from 'airborne/store/modules/search_air/selectors';

import AirAvailabilityEditor from './components/AirAvailabilityEditor';
import AirSearchTabs from './components/AirSearchTabs';
import AirFlightSearchEditor from './components/AirFlightSearchEditor';
import {valueShape} from './shapes';
import {AIR_SEARCH_TYPES} from 'airborne/air/homepage/types';
import {PRODUCT_TYPES, setBroadStreetTargets} from 'airborne/helpers/setBroadStreetTargets';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {currentCompany} from 'airborne/air/homepage/helpers/search';
import {isOneWayMode} from 'airborne/air/homepage/helpers/common';

const connectDecorator = connect(state => {
    const {pnr} = getPnrProfile(state, 0);
    const {value, errors} = getDestForm(state);
    const collapsableSections = getCollapsableSections(state);
    const searchType = getAirSearchType(state);
    const loading = isFaresLoading(state) || isAvailabilityLoading(state);
    const {company: {gdsName}} = getPnrForm(state);

    return {
        companySmid: getCompanySmid(state),
        value,
        errors,
        pnr,
        companyId: getSelectedConfiguration(state) || currentCompany(state),
        loading,
        collapsableSections,
        searchType,
        gdsName,
    };
}, {
    onChange: changeDestination,
    onReuseDestination: reuseDestination,
    searchAir,
    toggleCollapsableSection,
    requestAvailability,
});
export class AirDestinationSearch extends React.Component {
    static propTypes = {
        // From parent
        editable: PropTypes.bool,
        hideTabs: PropTypes.bool,
        disableRecentSearches: PropTypes.bool,
        disableSearch: PropTypes.bool,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,

        // from mapStateToProps
        value: valueShape,
        errors: PropTypes.object,
        pnr: PropTypes.string,
        companyId: PropTypes.number.isRequired,
        loading: PropTypes.bool,
        collapsableSections: PropTypes.objectOf(PropTypes.bool),
        searchType: PropTypes.string,
        gdsName: PropTypes.string.isRequired,
        underlyingProvider: PropTypes.string,
        isAirExchangeFlow: PropTypes.bool,
        companySmid: PropTypes.string,

        // from mapDispatchToProps
        onReuseDestination: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        searchAir: PropTypes.func.isRequired,
        toggleCollapsableSection: PropTypes.func,
        changeSearchType: PropTypes.func,
        requestAvailability: PropTypes.func,
    }

    getDestinationLabel = () => {
        const {value: {originDestinations}} = this.props;
        return originDestinations && originDestinations[0].dropOff.label;
    };

    setAdsTargeting = () => {
        const {companySmid} = this.props;

        setBroadStreetTargets({
            product: PRODUCT_TYPES.FLIGHTS,
            smid: companySmid,
            destination: this.getDestinationLabel(),
        });
    }

    onFormErrorSubmit(errors) {
        const {toggleCollapsableSection} = this.props;

        if (Boolean(errors.ftNumbers)) {
            toggleCollapsableSection('frequentFlyerNumber', true);
        }
    }

    handleFlightSearch = () => {
        const {value, onChange, searchAir, isFirstExchangeOriginDestinationFlown} = this.props;
        const errors = Schema.validate(value, {oneWay: isOneWayMode(value.tripType), strict: true, isFirstExchangeOriginDestinationFlown});
        if (noErrors(errors)) {
            this.setAdsTargeting();
            searchAir();
        }
        else {
            this.onFormErrorSubmit(errors);
            onChange({value, errors});
        }
    };

    handleAvailabilitySearch = () => {
        const {value, onChange, requestAvailability, isFirstExchangeOriginDestinationFlown} = this.props;
        const errors = Schema.validate(value, {oneWay: isOneWayMode(value.tripType), strict: true, isFirstExchangeOriginDestinationFlown});
        if (noErrors(errors)) {
            this.setAdsTargeting();
            requestAvailability();
        }
        else {
            this.onFormErrorSubmit(errors);
            onChange({value, errors});
        }
    }

    renderEditor() {
        const {
            searchType,
            editable,
            value: {tripType},
            collapsableSections,
            toggleCollapsableSection,
            isFirstExchangeOriginDestinationFlown
        } = this.props;

        const oneWay = isOneWayMode(tripType);

        if (searchType === AIR_SEARCH_TYPES.AVAILABILITY) {
            return (
                <AirAvailabilityEditor
                    schema={Schema}
                    schemaParams={{oneWay, isFirstExchangeOriginDestinationFlown}}
                    onSubmit={this.handleAvailabilitySearch}
                    disabled={!editable}
                    collapsableSections={collapsableSections}
                    toggleCollapsableSection={toggleCollapsableSection}
                    {...this.props}
                />
            );
        }

        return (
            <AirFlightSearchEditor
                schema={Schema}
                schemaParams={{oneWay, isFirstExchangeOriginDestinationFlown}}
                onSubmit={this.handleFlightSearch}
                disabled={!editable}
                collapsableSections={collapsableSections}
                toggleCollapsableSection={toggleCollapsableSection}
                {...this.props}
            />
        );
    }

    render() {
        const {editable, pnr, hideTabs} = this.props;

        return (
            <Fragment>
                <h4 className="block-title">
                    {gettext('Add flight segment to your PNR:')}
                    {editable && <strong> {pnr || gettext('Not selected')}</strong>}
                </h4>
                {!hideTabs && <AirSearchTabs />}
                {this.renderEditor()}
            </Fragment>
        );
    }
}

const ConnectedAirDestinationSearch = connectDecorator(AirDestinationSearch);
export default ConnectedAirDestinationSearch;
