import {
    getCompany,
    getDestForm
} from 'airborne/store/modules/homepage/selectors/homepage';
import {
    getPnrProfile
} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import isObject from "lodash/isObject";
import every from "lodash/every";
import values from "lodash/values";

function selectProfile(profile, {traveler}) {
    if (!traveler || traveler.myself) {
        return profile;
    }

    return {
        ...profile,
        'company': traveler.companyId,
        'company_name': traveler.company,
        'tspm_company_id': traveler.tspmCompanyId || null,
        'tspm_traveler_id': traveler.tspmTravelerId || null,
    };
}

export function currentCompany(state) {
    const dest = getDestForm(state);
    const profile = selectProfile(getPnrProfile(state, 0), dest.value);
    const company = getCompany(state);
    return profile ? profile.company : (company?.value || null);
}

export function dropEmpty(object) {
    const ret = {};
    Object.entries(object).forEach(([key, value])=> {
        if (value !== null && value !== '' && value !== undefined && !(Array.isArray(value) && value.length === 0)) { //eslint-disable-line no-undefined
            ret[key] = value;  // eslint-disable-line immutable/no-mutation
        }
    });
    return ret;
}

export function noErrors(errors) {
    if (!isObject(errors)) { return !errors; }
    return every(values(errors), noErrors);
}
