// copied from common/Loader.js
import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';

export default class RatesLoader extends React.Component {
    static propTypes = {
        ready: PropTypes.bool,
        children: PropTypes.any,
        title: PropTypes.string,
    };

    renderLoader() {
        return (<div className="container">
            <div className="search-preloader">
                <div className="search-preloader__title">
                    {this.props.title}
                </div>

                <div className="search-preloader__text-wrapper">
                    <ul className="search-preloader__text">
                        <li>{gettext('Searching rates and availability')}</li>
                        <li>{gettext('Almost there')}</li>
                        <li>{gettext('We appreciate your patience')}</li>
                    </ul>
                </div>

                <div className="search-preloader__bar">
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                    <div className="search-preloader__bar-item" />
                </div>
            </div>
        </div>);
    }

    render() {
        const {children, ready} = this.props;
        return (children && ready) ? children : this.renderLoader();
    }
}
