// copied from checkout2/InputTable.js
import React from 'react';
import PropTypes from 'prop-types';
import FieldLabel from 'midoffice/newforms/components/FieldLabel';
import FieldError from 'midoffice/newforms/components/FieldError';
import classNames from 'classnames';

export default class InputTable extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        splitErrors: PropTypes.bool,
        labelCol: PropTypes.number,
        inputCol: PropTypes.number,
        children: PropTypes.any,
        required: PropTypes.bool,
    };

    static contextTypes = {
        form: PropTypes.shape({
            errors: PropTypes.object,
        }),
    };

    renderInput(errors, input, idx) {
        const {errorField} = this.context.form;

        if (!input) {
            return null;
        }
        const msg = errors && errors[input.props.name];
        return (
            <td style={{width: input.props.tdWidth}} key={idx} className={msg ? 'has-error' : null}>
                {input}
                {errorField === input.props.name && !this.props.splitErrors && <FieldError errors={msg} />}
            </td>
        );
    }

    hasErrors() {
        const {errors = {}} = this.context.form;
        const {children} = this.props;
        const inputs = React.Children.map(children, (input) => input.props.name);
        return inputs.some(inputName => Boolean(errors[inputName]));
    }

    render() {
        const {children, label, required, inputCol, labelCol, splitErrors, highlightErrors} = this.props;
        const {errors} = this.context.form;
        const formGroupCls = classNames('form-group', {'has-error': highlightErrors && this.hasErrors()});
        return (
            <div className={formGroupCls}>
                {label && (
                    <FieldLabel label={label} required={required} className={`control-label col-xs-${labelCol || 2}`} />
                )}
                <div className={`col-xs-${inputCol}`}>
                    <table className="table-inline-inputs">
                        <tbody>
                            <tr>
                                {React.Children.map(children, (input, idx) => this.renderInput(errors, input, idx))}
                            </tr>
                            {splitErrors ? (
                                <tr>
                                    <td>
                                        {errors.first_name && <div className="error-msg show">{errors.first_name}</div>}
                                    </td>
                                    <td>
                                        {errors.middle_name && (
                                            <div className="error-msg show">{errors.middle_name}</div>
                                        )}
                                    </td>
                                    <td>
                                        {errors.last_name && <div className="error-msg show">{errors.last_name}</div>}
                                    </td>
                                </tr>
                            ) : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
