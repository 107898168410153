import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getAirExchangeFormValue} from './form';
import {createAirAvailabilityRequestParams} from 'airborne/air/availability/helpers/serialize';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

import {
    getAirExchangeBookingKey,
    isFirstExchangeOriginDestinationFlown,
} from 'airborne/store/modules/exchange_air/selectors/params';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import {createAirRequestParams} from 'airborne/air/fare_search/helpers';

export const getExchangeAvailabilityRequestParams = (state) => {
    const profile = getPnrProfile(state, 0);
    const value = getAirExchangeFormValue(state);
    const exchangeKey = getAirExchangeBookingKey(state);

    return createAirAvailabilityRequestParams(
        value,
        profile,
        exchangeKey,
        isFirstExchangeOriginDestinationFlown(state),
        getSelectedConfiguration(state),
    );
};

export const getExchangeSearchRequestParams = state => {
    const profile = getPnrProfile(state, 0);
    const value = getAirExchangeFormValue(state);
    const currency = getCurrency(state);
    const exchangeKey = getAirExchangeBookingKey(state);

    return createAirRequestParams(
        value,
        profile,
        currency,
        exchangeKey,
        getSelectedConfiguration(state),
    );
};
