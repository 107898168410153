import React from 'react';
import {injectFormContext} from 'midoffice/newforms/decorators';
import PropTypes from 'prop-types';
import gettext from 'airborne/gettext';
import Row from 'react-bootstrap/Row';
import settings from 'airborne/settings';
import Select from 'midoffice/newforms/widgets/Select';
import Col from 'react-bootstrap/Col';

import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';
import Button from 'midoffice/components/Button';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';

import SectionRow from './SectionRow';
import IdNumbers from './IdNumbers';
import AirDestinationAutocomplete from '../AirDestinationAutocomplete';
import AddMore from './AddMore';
import AirTransferAirports from './AirTransferAirports';
import AirAirlineCodesField from './AirAirlineCodesField';
import AirAlliancesField from './AirAlliancesField';
import AirDestinationField from './AirDestinationField';

import {AIR_TRIP_TYPES} from '../types';
import {valueShape} from '../shapes';

import {
    getAirTripTypeTuples, getSearchChoicesValue,
    handleTripTypeChange, handleSearchOptionsChange,
} from '../helpers/common';

import {prepareChoices} from "airborne/air/homepage/helpers/prepareChoices";
import {INITIAL_ORIGIN_DESTINATION_VALUE} from 'airborne/store/modules/homepage/reducers/destination';

@injectFormContext
export default class Editor extends React.Component {

    static propTypes = {
        value: valueShape,
        errors: PropTypes.any,
        disabled: PropTypes.bool.isRequired,
        onReuseDestination: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        companyId: PropTypes.number.isRequired,
        onSubmit: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        collapsableSections: PropTypes.objectOf(PropTypes.bool).isRequired,
        toggleCollapsableSection: PropTypes.func.isRequired,
        gdsName: PropTypes.string.isRequired,
        disableRecentSearches: PropTypes.bool,
        underlyingProvider: PropTypes.string,
        modifySearchFlags: PropTypes.object,
        isAirExchangeFlow: PropTypes.bool,
        disableSearch: PropTypes.bool,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
    };

    destinationFieldUpdates = 0;

    connectionsAutocomplete = new AirDestinationAutocomplete();

    isSabreExchange = () => {
        const {isAirExchangeFlow, provider} = this.props;
        return isAirExchangeFlow && provider === 'sabre';
    }

    // [name, label, description, group] tuple
    getSearchChoices() {
        const {gdsName} = this.props;
        const withBaggage = ['Apollo', 'Galileo'].every(gds => gds !== gdsName);
        const isSabre = gdsName === 'Sabre';

        return [
            ['directFlights', gettext('Direct Flights Only'), null, gettext('Search Options')],
            ['refundableOnly', gettext('Refundable'), null, gettext('Search Options')],
            ...(withBaggage
                ? [['allowedBaggage', gettext('Bags Included'), null, gettext('Search Options')]]
                : []
            ),
            ...(isSabre
                ? [['changeableOnly', gettext('Changeable Only'), null, gettext('Search Options')]]
                : []
            ),
            ['excludePenalties', gettext('No Penalties'), null, gettext('Search Options')],
        ];
    }

    getSearchChoicesValue() {
        const {value} = this.props;

        return getSearchChoicesValue(value, this.getSearchChoices());
    }

    handleSearchOptionsChange = (newValue) => {
        const {value, onChange} = this.props;
        const form = handleSearchOptionsChange(value, newValue, this.getSearchChoices());

        onChange(form);
    };

    handleFormTripTypeChange = (tripType) => {
        const {value, errors, onChange} = this.props;
        const form = handleTripTypeChange(value, errors, tripType);

        onChange(form);

        // We need to reset AirDestinationField state after Trip Type change,
        // in this way we're preventing EMPTY destinations from MultiCity displaying on OneWay/RoundTrip
        this.destinationFieldUpdates++;
    };

    getFirstRemainingDestIndex = () => {
        const {value: {originDestinations = []}} = this.props;
        return originDestinations.findIndex(dest => !dest.flownIndicator);
    };

    render() {
        const {
            onReuseDestination, companyId, onSubmit,
            disabled, loading, value, collapsableSections,
            toggleCollapsableSection, disableRecentSearches,
            underlyingProvider, modifySearchFlags, isAirExchangeFlow,
            disableSearch, isFirstExchangeOriginDestinationFlown,
        } = this.props;
        const {tripType, originDestinations, connections, airlineAlliances} = value;
        const autocompleteExtra = {companyId, product: 'air'};
        const originDestinationChangeNotAvailable =
            isAirExchangeFlow &&
            underlyingProvider === 'ba' &&
            !modifySearchFlags?.originDestinationChange;

        const isSabreExchange = this.isSabreExchange();
        const changingTripTypeDisabled = originDestinationChangeNotAvailable || isSabreExchange;

        return (<div className="ag-home-form ag-home-form--wide-labels">
            <Row>
                <div className="ag-mode__selector">
                    <Select.Field
                        name="tripType"
                        choices={getAirTripTypeTuples()}
                        onChange={this.handleFormTripTypeChange}
                        required={true}
                        disabled={changingTripTypeDisabled}
                    />
                </div>
            </Row>

            <AirDestinationField.Field
                name="originDestinations"
                companyId={companyId}
                noAddMore={tripType !== AIR_TRIP_TYPES.MULTI_CITY}
                addMoreLabel={gettext('+ Add Route')}
                addMoreComponent={AddMore}
                onReuseDestination={onReuseDestination}
                tripType={tripType}
                multi
                plainWidget
                disableRecentSearches={disableRecentSearches}
                key={this.destinationFieldUpdates}
                defaultValue={INITIAL_ORIGIN_DESTINATION_VALUE}
                originDestinations={originDestinations}
                disabled={originDestinationChangeNotAvailable}
                isSabreExchange={isSabreExchange}
                firstRemainingDestIndex={this.getFirstRemainingDestIndex()}
                isFirstExchangeOriginDestinationFlown={isFirstExchangeOriginDestinationFlown}
            />

            <hr className="ag-home-form__hr" />
            <Row>
                <div className="form-group">
                    <label className="control-label col-xs-2">Search Options</label>
                    <Col xs={9}>
                        <MultiCheckbox
                            name="select"
                            value={this.getSearchChoicesValue()}
                            choices={this.getSearchChoices()}
                            onChange={this.handleSearchOptionsChange}
                            testIdPrefix="air-homepage-fare-search-options"
                            table={false}/>
                    </Col>
                </div>
            </Row>
            <hr className="ag-home-form__hr" />
            <SectionRow
                title={gettext('Airlines & Alliances')}
                open={collapsableSections['airlines']}
                name="airlines"
                handleToggle={toggleCollapsableSection}
            >
                <Row>
                    <div className="form-group">
                        <AirAirlineCodesField />
                    </div>
                </Row>
                <Row>
                    <div className="form-group">
                        <AirAlliancesField chosenValues={airlineAlliances} />
                    </div>
                </Row>
            </SectionRow>
            <SectionRow
                title={gettext('Transfer Airport(s)')}
                open={collapsableSections['transferAirport']}
                name="transferAirport"
                handleToggle={toggleCollapsableSection}
            >
                <AirTransferAirports
                    autocompleteSource={this.connectionsAutocomplete}
                    autocompleteExtra={{...autocompleteExtra, recentSearches: false}}
                    required={false}
                    disabled={originDestinationChangeNotAvailable}
                    multiCount={originDestinations?.length}
                    tripType={tripType}
                    value={connections}
                />
            </SectionRow>
            <SectionRow
                title={gettext('Frequent Flyer Number(s)')}
                open={collapsableSections['frequentFlyerNumber']}
                name="frequentFlyerNumber"
                handleToggle={toggleCollapsableSection}
                data-testid="frequent-flyer-number-section"
            >
                <IdNumbers.Field multi
                    plainWidget
                    addMoreComponent={AddMore}
                    addMoreLabel={gettext('+ Add a Frequent Flyer Number')}
                    addMoreTestId="air-homepage-fare-search-add-ff-number"
                    name="ftNumbers"
                    disabled={disabled}
                    inputPlaceholder={gettext('Frequent Flyer Number')}
                    className="form-group--well"
                    vendors={prepareChoices(settings.AIRLINES)}/>
            </SectionRow>
            <div className="ag-home-form__control ag-home-form__control--bottom">
                <Button className="pull-right"
                    bsStyle="success"
                    onClick={onSubmit}
                    progress={loading}
                    disabled={disableSearch}
                    data-testid="find-flight-search-btn"
                >
                    <Shortcut label={gettext('Search')} shortcut={SHORTCUTS_LIST.G} action={onSubmit} />
                </Button>
            </div>
        </div>);
    }
}
