import moment from 'moment';

function upper(match, group0) {
    return group0.toUpperCase();
}

function normKey(key) {
    return key.replace(/_([a-z])/g, upper);
}

export default function normalize(data) {
    if (Array.isArray(data)) {
        return data.map(normalize);
    }

    if (!data || (typeof data) !== 'object') {
        return data;
    }
    if (moment.isMoment(data)) {
        return data;
    }
    const ret = {};
    Object.entries(data).forEach(([key, val])=> {
        ret[normKey(key)] = normalize(val); // eslint-disable-line immutable/no-mutation
    });
    return ret;
}
