import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classNames from 'classnames';
import gettext from 'airborne/gettext';
import AnnotateTooltip from "airborne/search2/hotel/AnnotateTooltip";
import Button from "midoffice/components/Button";
import Glyphicons from "midoffice/components/Glyphicons";
import {copyToClipboard} from "airborne/search2/helpers/copyToClipboard";
import {showQuickAlert} from "airborne/store/modules/homepage/actions";

@connect(null, (dispatch) => ({
    showAlert: (message) => dispatch(showQuickAlert(message))
}))
export class PNRCopyButton extends React.Component {
    static propTypes = {
        pnr: PropTypes.string.isRequired,
        enablePnrCopyBtn: PropTypes.bool,
        showAlert: PropTypes.func,
        cls: PropTypes.string,
    };

    state = {clicked: false}

    onCopyBtnClick = () => {
        const {pnr, showAlert} = this.props;

        if (this.state.clicked) {
            return;
        }

        copyToClipboard(pnr);
        this.setState({clicked: true});
        // TODO: GG-33279 Add single constant of user action timeout
        setTimeout(() => this.setState({clicked: false}), 2000);

        showAlert(gettext('Successfully copied to clipboard.'));
    };

    render() {
        const {copyBtnClass} = this.props;
        return (
            <AnnotateTooltip id="copy-pnr-to-clipboard">
                <Button bsStyle="light" onClick={this.onCopyBtnClick} disabled={this.state.clicked} data-heap="copy-pnr" className={classNames("btn-xs", copyBtnClass)}>
                    <span>
                        {!this.state.clicked ? <Glyphicons bsClass="glyphicon" glyph="copy" /> : <Glyphicons bsClass="glyphicon" glyph="ok" />}{' '}
                    </span>
                </Button>
                {gettext('Copy PNR to the clipboard.')}
            </AnnotateTooltip>
        );
    }
}
