import {dropEmpty} from 'midoffice/helpers/urlParams';

import {parse} from 'airborne/types';

import air from 'airborne/store/modules/search_hotels/actions/air';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {serializeTSPM} from 'airborne/homepage2/helpers/search';
import {getSelectedRateKey} from 'airborne/cars/checkout/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

export function loadCheckoutData() {
    return function loadCheckoutDataD(dispatch, getState) {
        const rateKey = getSelectedRateKey(getState());
        const profile = getPnrProfile(getState(), 0);

        dispatch({type: 'CARS_CHECKOUT_DATA_LOADING'});

        return air('GET', '/cars/get_checkout_data/', dropEmpty({
            'configuration_id': getSelectedConfiguration(getState()) || profile.company,
            'rate_key': rateKey,
            ...serializeTSPM(profile),
        })).then((data)=> {
            dispatch({
                type: 'CARS_CHECKOUT_DATA_LOADED',
                checkoutData: parse('carCheckoutData', data),
            });
        }).catch(()=> {
            dispatch({
                type: 'CARS_CHECKOUT_DATA_FAIL',
            });
        });
    };
}
