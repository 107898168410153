import omit from 'lodash/omit';
import {
    injectParamsToDestinations, injectParamsToPartiallyFlownDestinations,
    serializeDestination,
} from 'airborne/air/fare_search/helpers/destinations';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {serializeFTNumbers} from 'airborne/air/fare_search/helpers';

function getDestinations(formValue, isFirstODFlown = false) {
    let destinations = isFirstODFlown ?
        injectParamsToPartiallyFlownDestinations(formValue) :
        injectParamsToDestinations(formValue);

    return destinations.map((destination, index) => (
        omit(serializeDestination(destination, index, formValue), 'cabin')
    ));
}

export function createAirAvailabilityRequestParams(value, profile, exchangeKey, isFirstODFlown, configurationId) {
    return dropEmpty({
        'configuration_id': configurationId || profile.company,
        'origin_destinations': getDestinations(value, isFirstODFlown),
        'max_gds_flight_options': 25,
        'exchange_key': exchangeKey,
        travelers: [{
            'traveler_type': 'adult', // hardcode until we have option on UI
            'ff_numbers': serializeFTNumbers(value.ftNumbers),
        }],
    });
}
