import isEmpty from 'lodash/isEmpty';

import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import {connect} from 'react-redux';
import settings from 'airborne/settings';
import {gettext, ngettext} from 'airborne/gettext';

import {changeProduct} from 'airborne/store/modules/homepage/actions';
import DestinationSearch from './DestinationSearch';
import CarsDestinationSearch from '../cars/homepage/CarsDestinationSearch';
import Highlight from 'airborne/homepage2/Highlight';
import AirDestinationSearch from 'airborne/air/homepage';

import {guestTravelerEnabled} from 'airborne/search2/helpers/hyatt';
import {
    isMultiPnr,
    hasGuestTraveler,
    isAddPnr,
    getPnrProfile,
    isModification
} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getOptions, getStep, isPnrReady} from 'airborne/store/modules/homepage/selectors/homepage';
import {getProduct} from 'airborne/store/modules/homepage/selectors/product';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {MicroFrontEnd} from 'airborne/microFrontEnds/MicroFrontEnds';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';


class ProductTab extends React.Component {
    static propTypes = {
        product: PropTypes.oneOf(['hotels', 'cars', 'air', 'rail', 'ferrari']).isRequired,
        label: PropTypes.string.isRequired,
        isActive: PropTypes.bool.isRequired,
        disabledReason: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        fancyTooltip: PropTypes.string,
        editable: PropTypes.bool.isRequired,
    };

    shortcuts = {
        hotels: SHORTCUTS_LIST.H,
        cars: SHORTCUTS_LIST.C,
        air: SHORTCUTS_LIST.F,
    };

    wrapfancyTooltip(children) {
        const {fancyTooltip, editable, disabledReason} = this.props;
        return fancyTooltip && editable && !disabledReason
            ? (<Highlight id={fancyTooltip} fancy placement="bottom">{children}</Highlight>)
            : children;
    }

    handleClick = (e) => {
        e?.preventDefault();
        const {product, onSelect, disabledReason, editable} = this.props;
        if (!disabledReason && editable) {
            onSelect(product);
        }
    };

    render() {
        const {isActive, disabledReason, label, product, editable} = this.props;
        const className = classnames('tabs-inner__item', `js-product-${product}`, {
            'tabs-inner__item--active': isActive,
            'tabs-inner__item--disabled': Boolean(disabledReason),
        });

        return this.wrapfancyTooltip(
            <a href="#" className={className} onClick={this.handleClick} data-testid={`homepage-product-tab-${product}`}>
                <Shortcut label={label} shortcut={this.shortcuts[product]} action={this.handleClick} />
                {disabledReason && <div className="text-gray">{disabledReason}</div>}
            </a>
        );
    }
}


class ProductTabs extends React.Component {
    static propTypes = {
        selected: PropTypes.oneOf(['hotels', 'cars', 'air', 'rail', 'ferrari']).isRequired,
        onSelect: PropTypes.func.isRequired,
        carsDisabledReason: PropTypes.string,
        airDisabledReason: PropTypes.string,
        hotelsDisabledReason: PropTypes.string,
        railDisabledReason: PropTypes.string,
        editable: PropTypes.bool.isRequired,
        showRail: PropTypes.bool,
        showFerrari: PropTypes.bool,
    };

    render() {
        const {selected, onSelect, carsDisabledReason, hotelsDisabledReason, airDisabledReason, editable, showRail, showFerrari, railDisabledReason,} = this.props;

        return (
            <div className="tabs-inner">
                <nav className="tabs-inner__controls">
                    <ProductTab
                        product="hotels"
                        label={ngettext('Hotel', 'Hotels', 2)}
                        onSelect={onSelect}
                        editable={editable}
                        disabledReason={hotelsDisabledReason}
                        isActive={selected === 'hotels'} />
                    <ProductTab
                        product="cars"
                        label={ngettext('Car','Cars', 2)}
                        onSelect={onSelect}
                        editable={editable}
                        isActive={selected === 'cars'}
                        disabledReason={carsDisabledReason}
                        fancyTooltip="cars-tooltip" />
                    <ProductTab
                        product="air"
                        label={ngettext('Flight', 'Flights', 2)}
                        onSelect={onSelect}
                        editable={editable}
                        isActive={selected === 'air'}
                        disabledReason={airDisabledReason} />
                    {showRail && (
                        <ProductTab
                            product="rail"
                            label={gettext('Rail')}
                            onSelect={onSelect}
                            isActive={selected === 'rail'}
                            editable={editable}
                            disabledReason={railDisabledReason}
                    />)}
                    {showFerrari && <ProductTab
                        product="ferrari"
                        label={gettext('Ferrari')}
                        onSelect={onSelect}
                        isActive={selected === 'ferrari'}
                        editable={editable}
                    />}
                </nav>
            </div>
        );
    }
}

function hasGuestWarningWithMultiPNR(state) {
    return isMultiPnr(state) && !guestTravelerEnabled(state) && hasGuestTraveler(state);
}

const MODIFICATION_FLOW_DISABLE_HINT = gettext('Not available in PNR modification mode');
export function getHotelsDisabledReason(state) {
    const options = getOptions(state);
    const carModificationFlow = getCarModificationFlowBool(state);

    if (carModificationFlow) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['hotel_content_enabled'] === false) {
        return gettext('All content disabled');
    }

    return null;
}

export function getCarsDisabledReason(state) {
    const options = getOptions(state);
    const activeModificationMode = isModification(state);

    if (activeModificationMode) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }


    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_cars_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    if (isMultiPnr(state)) {
        return gettext('Only single PNR');
    }

    if (options['is_cars_available_in_primary_gds'] === false) {
        return gettext('Not available in company\'s Primary GDS');
    }

    return null;
}

export function getAirDisabledReason(state) {
    const options = getOptions(state);
    const pnrProfile = getPnrProfile(state, 0);
    const carModificationFlow = getCarModificationFlowBool(state) || null;
    const activeModificationMode = isModification(state);

    if (carModificationFlow || activeModificationMode) {
        return MODIFICATION_FLOW_DISABLE_HINT;
    }

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_air_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    if (isMultiPnr(state)) {
        return gettext('Only single PNR');
    }

    if (!pnrProfile || isEmpty(pnrProfile)) {
        return null;
    }

    const pnrTravelers = pnrProfile['pnr_travelers'];

    if (pnrTravelers && pnrTravelers.length > 1) {
        return gettext('Only Single-PAX PNRs');
    }

    return null;
}

export function getRailDisabledReason(state) {
    const options = getOptions(state);

    if (!options || isEmpty(options)) {
        return null;
    }

    if (options['is_rail_enabled'] === false) {
        return gettext('Disabled for Company');
    }

    return null;
}

export function mapStateProps(state) {
    const isDest = getStep(state) === 'dest';
    const hasPnr = !hasGuestWarningWithMultiPNR(state) && !isAddPnr(state);

    const activeCarsBookingModificationFlow = getCarModificationFlowBool(state) || null;
    const carsDisabledReason = getCarsDisabledReason(state);
    const railDisabledReason = getRailDisabledReason(state);
    const airDisabledReason = getAirDisabledReason(state) || activeCarsBookingModificationFlow;
    const hotelsDisabledReason = getHotelsDisabledReason(state) || activeCarsBookingModificationFlow;

    const isAllTabsDisabled = hotelsDisabledReason && carsDisabledReason && airDisabledReason;
    const editable = (isDest || isPnrReady(state)) && hasPnr;

    return {
        carsDisabledReason,
        airDisabledReason,
        hotelsDisabledReason,
        railDisabledReason,
        product: getProduct(state),
        editable: isAllTabsDisabled ? false : editable,
        showRail: getFeatureFlag(state, 'RAIL_ENABLED'),
        showFerrari: getFeatureFlag(state, 'FERRARI_ENABLED'),
    };
}


export class Right extends React.Component {
    static propTypes = {
        carsDisabledReason: PropTypes.string,
        airDisabledReason: PropTypes.string,
        hotelsDisabledReason: PropTypes.string,
        railDisabledReason: PropTypes.string,
        editable: PropTypes.bool.isRequired,
        product: PropTypes.oneOf(['hotels', 'cars', 'air', 'rail', 'ferrari']).isRequired,
        changeProduct: PropTypes.func.isRequired,
        showRail: PropTypes.bool,
        showFerrari: PropTypes.bool,
    };

    render() {
        const {
            editable,
            product,
            changeProduct,
            airDisabledReason,
            hotelsDisabledReason,
            carsDisabledReason,
            railDisabledReason,
            showRail,
            showFerrari,
        } = this.props;
        const Content = {
            hotels: DestinationSearch,
            cars: CarsDestinationSearch,
            air: AirDestinationSearch,
            rail: () => <MicroFrontEnd name="rail" />,
            ferrari: () => <MicroFrontEnd name="ferrari" />,
        }[product];

        return (
            <div>
                {!editable && <div className="inactive-mask" />}
                {settings.SUB_PARTNER === 'aft' &&
                    <ProductTabs onSelect={changeProduct}
                        selected={product}
                        carsDisabledReason={carsDisabledReason}
                        airDisabledReason={airDisabledReason}
                        hotelsDisabledReason={hotelsDisabledReason}
                        railDisabledReason={railDisabledReason}
                        editable={editable}
                        showRail={showRail}
                        showFerrari={showFerrari}
                    />}
                <Content {...this.props} />
            </div>
        );
    }
}


export default connect(mapStateProps, {changeProduct})(Right);
