// copied from airborne/utils/
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';


function formatHelper(price, currency=settings.CURRENCY_CODE, round=false, roundMethod=null) {
    price = price || 0;
    if (isString(price)) {
        price = parseFloat(price);
    }

    roundMethod = isFunction(roundMethod) ? roundMethod : Math.ceil;
    price = round ? roundMethod(price) : price.toFixed(2);
    currency = currency.toLowerCase();
    return currency.toUpperCase() + ' ' + price;
}

export function formatCode(currency=settings.CURRENCY_CODE) {
    currency = currency.toLowerCase();
    if (currency === '___') {
        return `${gettext('Local')} $€£`;
    }
    if (currency === '...') {
        return `${gettext('Company')} $€£`;
    }
    return currency.toUpperCase();
}

export function format(price, currency) {
    return formatHelper(price, currency, price >= 1, Math.round);
}

export function formatStrict(price, currency) {
    return formatHelper(price, currency, false);
}
