import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';
import {getDateLimits, getMultiCityDateLimits} from '../helpers/dateLimits';
import DateInput from 'midoffice/newforms/widgets/DateInput';
import settings from 'airborne/settings';
import gettext from 'airborne/gettext';
import moment from 'moment';

@injectField
export default class PseudoDateRange extends React.Component {
    static propTypes = {
        value: PropTypes.shape({
            min: PropTypes.any,
            max: PropTypes.any,
        }),
        errors: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        originDestinations: PropTypes.array,
        disabled: PropTypes.bool,
        index: PropTypes.number,
    };

    handleChange = (minValue) => {
        let {value = {}, onChange} = this.props;

        onChange({
            ...value,
            min: minValue && moment(minValue, settings.SHORT_DATE_FORMAT),
        }, 'dateRange');
    };

    render() {
        const {value, errors, originDestinations, index, disabled} = this.props;
        const {min: minDate, max: maxDate} = originDestinations.length <= 1
            ? getDateLimits()
            : getMultiCityDateLimits(originDestinations, index);

        return (<div className="input-daterange input-group">
            <DateInput
                name="min"
                value={value && value.min}
                errors={errors && errors.min}
                onChange={this.handleChange}
                required={false}
                minDate={minDate}
                maxDate={maxDate}
                hideOnDateChange
                className="form-control ag-home-daterange-single input-daterange"
                inputFormat={settings.SHORT_DATE_FORMAT}
                placeholder={gettext(settings.SHORT_DATE_FORMAT.toLowerCase())}
                data-testid="homepage-departure-date"
                disabled={disabled}
            />
        </div>);
    }
}
