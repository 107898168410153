// copied from search2/helpers/hyatt.js
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import {getOptions} from 'airborne/store/modules/homepage/selectors/homepage';

export function searchLimit(state) {
    const {'search_limit': searchLimit} = getOptions(state);
    return searchLimit;
}

export function noPnrEnabled(state) {
    const {'no_pnr_enabled': noPnrEnabled} = getOptions(state);
    return Boolean(noPnrEnabled);
}

export function guestTravelerEnabled(state) {
    const {'is_guest_traveler_enabled': guestEnabled} = getOptions(state);
    return Boolean(guestEnabled);
}

export function rateLabeling(state) {
    const {'rate_labeling': labeling} = getOptions(state);
    return labeling;
}

export function hotelLabeling(state) {
    const {'hotel_labeling': labeling} = getOptions(state);
    return labeling;
}

export function carsLabeling(state) {
    const {'cars_labeling': labeling} = getOptions(state);
    return labeling;
}

export function perDiemsEnabled(state) {
    const {'per_diems_enabled': perDiems} = getOptions(state);
    return Boolean(perDiems);
}

export function rateCaps(state) {
    const {'rate_caps': caps} = getOptions(state);
    return caps;
}

export function rateRecommendation(state) {
    const {'rate_optimization': enabled} = getOptions(state);
    return Boolean(enabled);
}

export function capsApplied(state) {
    return perDiemsEnabled(state) || !isEmpty(filter(rateCaps(state), 'active'));
}

export function isTyEnabled(state) {
    return Boolean(getOptions(state)['is_trustyou_enabled']);
}

export function isTopRatesEnabled(state) {
    return Boolean(getOptions(state)['rate_optimization_search']);
}

export function isCNPConfiguredToAsk(state) {
    return getOptions(state)['premierinn_guarantee_strategy'] === 'ask_on_checkout';
}
