import React from 'react';
import PropTypes from 'prop-types';
import {injectField} from 'midoffice/newforms/decorators';

import Autocomplete from 'midoffice/newforms/widgets/Autocomplete';

function getQueryAutocompleteOption(query, items) {
    if (!items?.length) {
        return null;
    }

    const matchedItems = items.filter(item => item.iataCode === query.toUpperCase());
    const airport = matchedItems.find(item => item.type === 'a');

    if (airport) {
        return airport;
    }

    return matchedItems[0];
}

@injectField
export default class DestinationSelect extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        queryMatchFn: PropTypes.func,
        testId: PropTypes.string,
        dropdownTestId: PropTypes.string,
    };

    focus() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    handleChange = (value)=> {
        if (value === '') {
            this.props.onChange(null, this.props.name);
        }
        else {
            this.props.onChange({label: value, value: null}, this.props.name);
        }
    };

    handleCommit = (value)=> {
        this.props.onChange(value, this.props.name);
    };

    handleReference = (input)=> {
        this.inputRef = input; // eslint-disable-line immutable/no-mutation
    };

    render() {
        const {value, queryMatchFn, testId, dropdownTestId} = this.props;
        return (
            <Autocomplete {...this.props}
                ref={this.handleReference}
                value={value ? value.label : null}
                onChange={this.handleChange}
                onCommit={this.handleCommit}
                queryMatchFn={queryMatchFn || getQueryAutocompleteOption}
                testId={testId}
                dropdownTestId={dropdownTestId}
            />
        );
    }
}
