import React, {Component, Suspense, lazy} from 'react';
import {Spinner} from 'airborne/air/shared/components/WaitBooking';

const Checkout = lazy(() => import('airborne/air/checkout'));

const Loader = () => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
        <Spinner product="air"/>
    </div>
);

export default class CheckoutExchange extends Component {
    render() {
        return (
            <Suspense fallback={Loader}>
                <Checkout />
            </Suspense>
        );
    }
}

