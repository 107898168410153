/* global IS_PROD,global */
import wrap from 'airborne/air/shared/components/LazyComponent';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import {isFaresLoading} from 'airborne/store/modules/search_air/selectors';
import Loader from 'airborne/air/shared/components/RatesLoader';

export function loadAirSearch() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./SearchAir');
            resolve(loaded);
        }, 'search_air');
    });
}

const AirLoader = connect(state => ({
    ready: !isFaresLoading(state),
    title: gettext('Loading flights, please wait…'),
}))(Loader);

const SearchAir = wrap(loadAirSearch, 'default', AirLoader);
export default SearchAir;

global.loadAirSearch = IS_PROD ? null : loadAirSearch; // eslint-disable-line immutable/no-mutation
