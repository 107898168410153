import React from 'react';
import PropTypes from 'prop-types';

import wrap from 'airborne/air/shared/components/LazyComponent';

import WaitBooking from 'airborne/air/fare_search/WaitBooking';
import {loadAirSearch} from 'airborne/air/fare_search/lazyloader';

class Loader extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };
    render() {
        const {children} = this.props;
        return children  || (<WaitBooking show />);
    }
}

export function loadAirCheckout() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./index');
            resolve(loaded);
        }, 'air-checkout');
    });
}

function load() {
    return Promise.all([loadAirCheckout(), loadAirSearch()])
        .then(([checkout])=> checkout);
}


const Checkout = wrap(load, 'default', Loader);

export default Checkout;
