import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {createAirAvailabilityRequestParams} from 'airborne/air/availability/helpers/serialize';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';

import {
    getAirExchangeBookingKey,
    isFirstExchangeOriginDestinationFlown,
} from 'airborne/store/modules/exchange_air/selectors';

export const getAvailabilityRequestParams = state => {
    const profile = getPnrProfile(state, 0);
    const value = getDestFormValue(state);
    const exchangeKey = getAirExchangeBookingKey(state);
    const isFirstODFlown = isFirstExchangeOriginDestinationFlown(state);

    return createAirAvailabilityRequestParams(value, profile, exchangeKey, isFirstODFlown, getSelectedConfiguration(state));
};
