import wrap from 'airborne/air/shared/components/LazyComponent';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';
import {isAvailabilityLoading} from 'airborne/store/modules/availability_air/selectors/flightOptions';
import Loader from 'airborne/air/shared/components/RatesLoader';

export function loadAirAvailability() {
    return new Promise((resolve)=> {
        require.ensure([], ()=> {
            const loaded = require('./AvailabilitySearch');
            resolve(loaded);
        }, 'availability_air');
    });
}

const AirAvailabilityLoader = connect(state => ({
    ready: !isAvailabilityLoading(state),
    title: gettext('Loading availability, please wait…'),
}))(Loader);

const AvailabilityAir = wrap(loadAirAvailability, 'default', AirAvailabilityLoader);
export default AvailabilityAir;
