import map from 'lodash/fp/map';
import unique from 'lodash/fp/uniq';
import compact from 'lodash/fp/compact';
import flow from 'lodash/flow';
import flatten from 'lodash/flatten';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import normalize from 'airborne/air/shared/helpers/normalize';
import {
    extractFareGroupPriceClassCode,
} from 'airborne/air/fare_search/helpers';

const extractFlightOptionKeys = (fareGroup) =>
    fareGroup.originDestinations.map(({flightOptionKey}) => flightOptionKey);

const extractFareBasisCodes = (fareGroup) =>
    flow(
        map(({fareBasisCode}) => fareBasisCode),
        compact,
        unique
    )(fareGroup.fareComponents);

const extractClassOfServices = (fareGroup) =>
    flow(
        map(originDestination => originDestination.segments),
        flatten,
        map(segment => segment.classOfService),
    )(fareGroup.originDestinations);

const extractFlightCabins = (fareGroup) =>
    flow(
        map(originDestination => originDestination.segments),
        flatten,
        map(segment => segment.cabin),
    )(fareGroup.originDestinations);

const parseFareGroups = (fareGroups) =>
    fareGroups
        .sort((a, b) => a.total - b.total)
        .map(fareGroup => ({
            ...fareGroup,
            fareBasisCodes: extractFareBasisCodes(fareGroup),
            priceClassCode: extractFareGroupPriceClassCode(fareGroup),
            flightOptionKeys: extractFlightOptionKeys(fareGroup),
            classOfServices: extractClassOfServices(fareGroup),
            flightCabins: extractFlightCabins(fareGroup),
        }));

const parsePricing = (response) => {
    const {fareGroups} = normalize(response);

    return parseFareGroups(fareGroups);
};

export const mergePricingResponses = (responses) => sortBy(
    unionWith(
        ...responses.map(parsePricing),
        (fareA, fareB) => {
            const fareBasisCodesEqual = isEqual(sortBy(fareA.fareBasisCodes), sortBy(fareB.fareBasisCodes));
            const fareTypesEqual = fareA.fareType === fareB.fareType;
            const pricesEqual = fareA.total === fareB.total;

            return fareBasisCodesEqual && fareTypesEqual && pricesEqual;
        }
    ),
    ['totalAfterTaxes']
);

export default parsePricing;
