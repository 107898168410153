import map from 'lodash/map';

import gettext from 'airborne/gettext';

import {createSchema, combineValidators} from 'midoffice/newforms/helpers';
import {
    CharField,
    ArrayField,
} from 'midoffice/newforms/fields-stateless';
import {AlphanumericField} from 'midoffice/newforms/fields-stateless';
import some from "lodash/some";

const validateUniqueness = (error)=> (list)=> {
    if (!list || !list.length) { return null; }

    const errors = list.map((item, index, list)=> {
        const firstIndex = list.indexOf(item);
        const lastIndex = list.lastIndexOf(item);

        if ((firstIndex !== lastIndex) && (firstIndex !== index)) {
            return error;
        }

        return null;
    });

    return some(errors) ? errors : null;
};

export const DiscountNumber = createSchema({
    fields: {
        number: {
            ...AlphanumericField,
            maxLength: 25,
            maxLengthMessage: (field)=> gettext('Ensure this field has no more than {maxLength} characters', field),
            invalidFormatMessage: ()=> gettext('Ensure this field has only alphanumeric characters'),
        },
        vendor: CharField,
    },
});

export const IdNumbersField = {
    ...ArrayField,

    child: DiscountNumber,

    validate: combineValidators(
        ArrayField.validate,
        (value)=> {
            const vendors = map(value, 'vendor');
            return validateUniqueness(gettext('Vendor is not unique'))(vendors);
        }
    ),

    toRepresentation(formValue, options) {
        let {value, errors} = ArrayField.toRepresentation.call(this, formValue, options);

        if (!value || !value.length) { value = []; }
        return {value, errors};
    }
};
