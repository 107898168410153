const ACRISS_CODE_LENGTH = 4;

export const CATEGORIES = {
    '*': 'Any Category',
    'M': 'Mini',
    'N': 'Mini Elite',
    'E': 'Economy',
    'H': 'Economy Elite',
    'C': 'Compact',
    'D': 'Compact Elite',
    'I': 'Intermediate',
    'J': 'Intermediate Elite',
    'S': 'Standard',
    'R': 'Standard Elite',
    'F': 'Fullsize',
    'G': 'Fullsize Elite',
    'P': 'Premium',
    'U': 'Premium Elite',
    'L': 'Luxury',
    'W': 'Luxury Elite',
    'O': 'Oversize',
    'X': 'Special',
};

export const TYPES = {
    '*': 'Any Type',
    'B': '2-3 Door',
    'C': '2/4 Door',
    'D': '4-5 Door',
    'W': 'Wagon/Estate',
    'V': 'Passenger Van',
    'L': 'Limousine',
    'S': 'Sport',
    'T': 'Convertible',
    'F': 'SUV',
    'J': 'Open Air All Terrain',
    'X': 'Special',
    'P': 'Pick up Regular Car',
    'Q': 'Pick up Extended Car',
    'Z': 'Special Offer Car',
    'E': 'Coupe',
    'M': 'Monospace',
    'R': 'Recreational Vehicle',
    'H': 'Motor Home',
    'Y': '2 Wheel Vehicle',
    'N': 'Roadster',
    'G': 'Crossover',
    'K': 'Commercial Van/Truck',
};

export const TRANSMISSIONS = {
    '*': 'Any Transmission',
    'M': 'Manual Unspecified Drive',
    'N': 'Manual 4WD',
    'C': 'Manual AWD',
    'A': 'Auto Unspecified Drive',
    'B': 'Auto 4WD',
    'D': 'Auto AWD',
};

export const FUELS = {
    '*': 'Any Fuel',
    'R': 'Unspecified Fuel With Air',
    'N': 'Unspecified Fuel Without Air',
    'D': 'Diesel Air',
    'Q': 'Diesel No Air',
    'H': 'Hybrid Air',
    'I': 'Hybrid No Air',
    'E': 'Electric Air',
    'C': 'Electric No Air',
    'L': 'LPG/Compressed Gas Air',
    'S': 'LPG/Compressed Gas No Air',
    'A': 'Hydrogen Air',
    'B': 'Hydrogen No Air',
    'M': 'Multi Fuel Air',
    'F': 'Multi Fuel No Air',
    'V': 'Petrol Air',
    'Z': 'Petrol No Air',
    'U': 'Ethanol Air',
    'X': 'Ethanol No Air',
};

export const PSEUDO_CODES = {
    'ACAR': 'Any Standard Vehicle',
    'ALLB': '2-3 Door',
    'ALLC': '2 or 4 Door',
    'ALLD': '4-5 Door',
    'AELT': 'Any Elite',
    'ACPR': 'Couple/Roadster',
    'ASPC': 'Non-Standard Fleet',
    'APUP': 'Any Pickup',
    'AWGN': 'Any Estate/Wagon',
    'AREC': 'Recreational Vehicle',
    'ASUV': 'Any SUV',
    'AVAN': 'Passenger Van',
    'ASIX': 'Any 6+ Pax Van',
    'ASEV': 'Any 7+ Pax van',
    'AEIG': 'Any 8-Pax+ Van',
    'ANIN': 'Any 9-Pax+ Van',
    'AFWD': 'Any 4WD/ AWD',
    'ATRV': 'All Terrain',
    'ACGO': 'Commercial Truck',
    'ALMO': 'Limousine',
    'ASPT': 'Sport',
    'ACNV': 'Convertible',
    'AOFR': 'Special Offer Car',
    'AMNO': 'Monospace',
    'AMTO': 'Motor Home',
    'AMCY': '2-Wheel Vehicle',
    'ACRS': 'Crossover',
    'AMAN': 'All Manual Transmission',
    'AUTO': 'All Automatic Transmission',
    'APET': 'All Petrol Powered Vehicles',
    'ADSL': 'All Diesel Powered Vehicles',
    'AGRN': 'Any Lower Emission Vehicles',
    'AHYB': 'All Hybrid Vehicles',
    'AELC': 'All Electric Powered Vehicles',
    'AHYD': 'All Hydrogen Powered Vehicles',
    'AMFP': 'All Multi Fuel Powered Vehicles',
    'ACPG': 'All LPG/Compressed Gas Powered Vehicles',
    'AETH': 'All Ethanol Powered Vehicles',
};

export function expandAcriss(value) {
    const [category, type, transmission, fuel] = value || '';
    return {category, type, transmission, fuel};
}


export function collapseAcriss({category, type, transmission, fuel}) {
    return `${category}${type}${transmission}${fuel}`;
}

export function prepareAcrissLabel(value, labels) {
    const label = labels[value];
    return value === '*' ? label : `${value} - ${label}`;
}

export function acriss(value, allowWildcard) {
    if (value.length !== ACRISS_CODE_LENGTH) { return false; }
    if (!allowWildcard && value.split('').includes('*')) { return false; }

    const {category, type, transmission, fuel} = expandAcriss(value);
    return ((category in CATEGORIES) &&
            (type in TYPES) &&
            (transmission in TRANSMISSIONS) &&
            (fuel in FUELS));
}

export function isPseudo(value) {
    return value in PSEUDO_CODES;
}

export function getExplanationText(code) {
    if (isPseudo(code)) { return PSEUDO_CODES[code]; }

    const {category, type, fuel, transmission} = expandAcriss(code);
    return [
        CATEGORIES[category],
        TYPES[type],
        TRANSMISSIONS[transmission],
        FUELS[fuel]
    ].join(' / ');
}
