import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import {injectField, injectFormContext} from 'midoffice/newforms/decorators';
import Select from 'midoffice/newforms/widgets/Select';
import Checkbox from 'midoffice/newforms/widgets/Checkbox';

import AirDestinationAutocomplete from '../AirDestinationAutocomplete';
import {getDateLimits} from 'airborne/air/homepage/helpers/dateLimits';
import DestinationSelect from 'airborne/air/homepage/components/DestinationSelect';
import DateRange from 'midoffice/newforms/widgets/DateRange';
import AirTimeRestrictionField from './AirTimeRestrictionField';
import PseudoDateRange from './AirPseudoDateRange';
import Glyphicons from 'midoffice/components/Glyphicons';
import {getCabinClassChoices, isOneWayMode} from '../helpers/common';

import {AIR_TRIP_TYPES} from '../types';
import {valueShape} from '../shapes';

const SABRE_EXCHANGE_PRESERVE_DEST_CHOICES = [
    ['Flights', gettext('Flights')],
    ['FlightsAndFares', gettext('Flights And Fares')],
    ['Connections', gettext('Connections')],
];

@injectFormContext
class AirDestinationEditor extends React.Component {
    static propTypes = {
        value: valueShape,
        errors: PropTypes.any,
        disabled: PropTypes.bool,
        editorDisabled: PropTypes.bool,
        onChange: PropTypes.func,
        companyId: PropTypes.number.isRequired,
        originAutocomplete: PropTypes.any.isRequired,
        destinationAutocomplete: PropTypes.any.isRequired,
        tripType: PropTypes.string,
        disableRecentSearches: PropTypes.bool,
        noCabin: PropTypes.bool,
        originDestinations: PropTypes.array,
        index: PropTypes.number,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
        isSabreExchange: PropTypes.bool,
    };

    render() {
        const {
            companyId, disabled, value, tripType, isSabreExchange,
            originAutocomplete, destinationAutocomplete, editorDisabled,
            disableRecentSearches, noCabin, originDestinations,
            index, isFirstExchangeOriginDestinationFlown,
        } = this.props;

        const oneWay = isOneWayMode(tripType);
        const isMultiCity = tripType === AIR_TRIP_TYPES.MULTI_CITY;
        const {noCabinPreference} = value;
        const autocompleteExtra = {companyId, product: 'air'};

        const cabinProps = noCabinPreference
            ? {
                disabled: true,
                value: null,
                allowEmpty: true,
                placeholder: gettext('Any'),
            }
            : {};

        const {min: minDate, max: maxDate} = getDateLimits();
        const departDate = moment(value?.dateRange?.min, 'YYYY-MM-DD', true);

        return (<>
            <Row>
                <DestinationSelect.Field
                    label={gettext('Origin')}
                    labelSuffix=""
                    inputCol={9}
                    name="pickUp"
                    placeholder={gettext('Enter origin city or airport')}
                    autocompleteSource={originAutocomplete}
                    autocompleteExtra={{...autocompleteExtra, recentSearches: !disableRecentSearches}}
                    className="ag-destination form-group--no--bottom"
                    required={false}
                    disabled={disabled || editorDisabled}
                    withAirDestinationInfo
                    dropdownTestId="air-homepage-origin-dropdown"
                />
            </Row>

            <Row>
                <DestinationSelect.Field
                    label={gettext('Destination')}
                    labelSuffix=""
                    inputCol={9}
                    name="dropOff"
                    placeholder={gettext('Enter destination city or airport')}
                    autocompleteSource={destinationAutocomplete}
                    autocompleteExtra={{...autocompleteExtra, recentSearches: false}}
                    className="ag-destination form-group--no--bottom"
                    required={false}
                    disabled={disabled || editorDisabled}
                    withAirDestinationInfo
                    dropdownTestId="air-homepage-destination-dropdown"
                />
            </Row>

            {isSabreExchange && isMultiCity && (
                <Row>
                    <Select.Field
                        name="preserveDest"
                        label={gettext('Preserve')}
                        labelCol={2}
                        inputCol={9}
                        allowEmpty
                        choices={SABRE_EXCHANGE_PRESERVE_DEST_CHOICES}
                        disabled={editorDisabled}
                    />
                </Row>
            )}

            <hr className="ag-home-form__hr"/>
            <Row>
                {!oneWay && !isFirstExchangeOriginDestinationFlown && (
                    <div className="ag-home-form__title">
                        <div className="ag-home-form__title__item">{gettext('Depart Flight')}</div>
                        <div className="ag-home-form__title__item">{gettext('Return Flight')}</div>
                    </div>
                )}
            </Row>
            <Row>
                {oneWay || isFirstExchangeOriginDestinationFlown
                    ? <PseudoDateRange.Field
                        name="dateRange"
                        label={gettext('Dates')}
                        labelSuffix=""
                        className="ag-home-daterange"
                        labelCol={2}
                        inputCol={5}
                        originDestinations={originDestinations}
                        index={index}
                        disabled={editorDisabled}
                    />
                    : <DateRange.Field
                        name="dateRange"
                        label={gettext('Dates')}
                        labelSuffix=""
                        required={false}
                        className="ag-home-daterange"
                        inputCol={9}

                        minTestId="homepage-departure-date"
                        maxTestId="homepage-return-date"


                        inputFormat={settings.SHORT_DATE_FORMAT}
                        placeholder={gettext(settings.SHORT_DATE_FORMAT.toLowerCase())}
                        minFrom={minDate}
                        minTo={maxDate}
                        maxFrom={departDate.isValid() ? departDate : minDate}
                        maxTo={maxDate}
                        plain
                        disabled={editorDisabled}
                    />
                }
            </Row>
            <Row>
                <div className="ag-form-group__split">
                    <AirTimeRestrictionField.Field
                        label={gettext('Departure Time')}
                        hint={'Leave empty for any time'}
                        labelSuffix=""
                        name="timeRestrictionOutbound"
                        inputCol={5}
                        data-testid="homepage-departure-time-restriction-time"
                        disabled={editorDisabled}
                    />
                    {!(oneWay || isFirstExchangeOriginDestinationFlown) && (
                        <AirTimeRestrictionField.Field
                            name="timeRestrictionInbound"
                            inputCol={5}
                            data-testid="homepage-return-time-restriction-time"
                            disabled={editorDisabled}
                        />
                    )}
                </div>
            </Row>
            {!noCabin && (
                <>
                    <Row>
                        <div className="ag-form-group__split">
                            <Select.Field
                                label={gettext('Cabin')}
                                labelSuffix=""
                                choices={getCabinClassChoices()}
                                required={false}
                                name="departureCabin"
                                inputCol={5}
                                {...cabinProps}
                            />

                            {!oneWay && <Select.Field
                                name="arrivalCabin"
                                choices={[
                                    ...getCabinClassChoices(),
                                    ['same', gettext('Same as Depart Class')],
                                ]}
                                required={false}
                                inputCol={5}
                                {...cabinProps}
                                {...(oneWay
                                    ? {
                                        value: null,
                                        allowEmpty: false,
                                    }
                                    : {})}
                            />}
                        </div>
                    </Row>
                    <Row>
                        <div className="ag-form-group__split">
                            <div className="ag-home__label-offset">
                                <Checkbox.Field
                                    name="noCabinPreference"
                                    label={gettext('No Cabin Preference')}
                                    inputCol={5}
                                    disabled={editorDisabled}
                                />
                            </div>
                        </div>
                    </Row>
                </>
            )}
        </>);
    }
}

@injectField
export default class AirDestinationField extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        onRemove: PropTypes.func,
        onReuseDestination: PropTypes.func.isRequired,
        value: PropTypes.object,
        errors: PropTypes.object,
        index: PropTypes.number,
        child: PropTypes.object,
        companyId: PropTypes.number,
        name: PropTypes.string,
        tripType: PropTypes.string,
        disableRecentSearches: PropTypes.bool,
        noCabin: PropTypes.bool,
        originDestinations: PropTypes.array,
        isFirstExchangeOriginDestinationFlown: PropTypes.bool,
        firstRemainingDestIndex: PropTypes.number,
        isSabreExchange: PropTypes.bool,
    };

    originAutocomplete = new AirDestinationAutocomplete('air_destination_lookup_1');
    destinationAutocomplete = new AirDestinationAutocomplete('air_destination_lookup_2');

    handleChange = (selected) => {
        const {onChange, onReuseDestination, name} = this.props;
        const selectedValuePickup = selected?.value?.pickUp || {};

        // Check if its comes from the Recent Searches
        // In this case 'selected.pickUp' will contain the Full Form Value
        if (selectedValuePickup.hasOwnProperty('expired')) {
            const {value: {pickUp, ...rest}} = selected;
            const filledForm = {
                ...rest,
                ...pickUp,
            };
            onReuseDestination(filledForm);
        }
        else {
            onChange(selected, name);
        }
    };

    renderRemove() {
        const {onRemove, tripType, index, firstRemainingDestIndex, value} = this.props;

        const isFirstRemainingDest = index === firstRemainingDestIndex
            && [AIR_TRIP_TYPES.MULTI_CITY, AIR_TRIP_TYPES.ROUND_TRIP].includes(tripType);

        const isFlownMultiCityDest = tripType === AIR_TRIP_TYPES.MULTI_CITY && value.flownIndicator

        if (isFirstRemainingDest || isFlownMultiCityDest) {
            return null;
        }

        return (
            <Button
                className={'highlight-red ag-mode__control'}
                variant="link"
                onClick={onRemove}
            >
                <Glyphicons bsClass="glyphicon" glyph="remove"/>
            </Button>
        );
    }

    render() {
        const {
            value,
            errors,
            child,
            index,
            companyId,
            tripType,
            disableRecentSearches,
            isFirstExchangeOriginDestinationFlown: isAnyDestFlown,
            firstRemainingDestIndex,
        } = this.props;

        const isFlownFlight = value.flownIndicator;
        const showFlownFlightsAsDest = tripType === 'multiCity';
        const isFirstDest = index === 0;
        const isFirstRemainingDest = index === firstRemainingDestIndex;

        if (isFlownFlight && !showFlownFlightsAsDest) {
            return null;
        }

        const classNames = cx({'ag-mode__multi-wrapper': tripType === 'multiCity' || isAnyDestFlown});
        return (
            <>
                {showFlownFlightsAsDest && isFirstDest && isAnyDestFlown && (
                    <h5 className="text-right">
                        <strong>{gettext('Already Flown Flight(s)')}</strong>
                        <hr className="ag-home-form__hr" />
                    </h5>
                )}
                {showFlownFlightsAsDest && isFirstRemainingDest && isAnyDestFlown && (
                    <h5 className="text-right">
                        <strong>{gettext('Remaining Flight(s)')}</strong>
                        <hr className="ag-home-form__hr" />
                    </h5>
                )}
                <div className={classNames}>
                    {this.renderRemove()}
                    <AirDestinationEditor
                        {...this.props}
                        schema={child}
                        onChange={this.handleChange}
                        value={value}
                        errors={errors}
                        originAutocomplete={this.originAutocomplete}
                        destinationAutocomplete={this.destinationAutocomplete}
                        companyId={companyId}
                        disableRecentSearches={disableRecentSearches || index !== 0}
                        editorDisabled={isFlownFlight}
                    />
                </div>
            </>
        );
    }
}
