import WaitBooking from 'airborne/air/shared/components/WaitBooking';
import {isCheckoutLoading} from 'airborne/store/modules/search_air/selectors';

import {connect} from 'react-redux';
import {isLoadingBookCarsForDestination} from 'airborne/store/modules/homepage/selectors/homepage';
import gettext from 'airborne/gettext';

export default connect(
    (state, {show})=> ({
        show: isCheckoutLoading(state) || show,
        product: 'air',
        message: isLoadingBookCarsForDestination(state) ? gettext('Please wait...') : undefined, //eslint-disable-line no-undefined
    })
)(WaitBooking);
