import {displayBedTypes} from 'airborne/search2/helpers/rates';
import gettext, {ngettext} from 'airborne/gettext';
import {formatStrict as formatPriceStrict, format as formatPrice} from 'airborne/utils/price';
import formatAddress from 'airborne/search2/helpers/address';
import {getExplanationText} from 'midoffice/data/schemas/helpers/acriss';

import normalize from 'airborne/search2/helpers/normalize';
import isEqual from 'lodash/isEqual';
import amenityFormat from 'airborne/search2/amenities';
import identity from "lodash/identity";
import compact from "lodash/compact";
import markup from "airborne/markup";

const formatAveragePerNight = ({currency, average}) => {
    const [formatCurrency, formatAverage] = formatPrice(average, currency).split(' ');
    return `${formatCurrency} ${formatAverage}`;
};

const formatAmenities = (amenitiesCodes) =>
    amenitiesCodes.map(code => amenityFormat(code)).join(', ') || gettext('Not Included');

export function formatCarAddress({streetAddress, city, state, postal, country}) {
    return formatAddress(
        streetAddress,
        city,
        state,
        postal,
        country
    );
}

export function formatMileage({units, value, process=markup}) {
    return units === 'KM'
        ? process(ngettext('**{value} free km** included', '**{value} free km** included', value, {value}))
        : process(ngettext('**{value} free mile** included', '**{value} free miles** included', value, {value}));
}

const getI18N = () => ({
    daily: gettext('daily'),
    weekend: gettext('weekend'),
    weekly: gettext('weekly'),
    monthly: gettext('monthly'),
    bundled: gettext('bundled'),
});

export const getRatePlanI18N = (ratePlan = {}) => ({
    ...ratePlan,
    type: getI18N()[ratePlan?.type?.toLowerCase()] || gettext('N/A'),
});

export function getMiliageText(distanceIncluded) {
    const {distanceAmount, distanceUnit, unlimited=false} = distanceIncluded;

    if (unlimited) {
        return gettext('Unlimited mileage');
    }
    else if (distanceAmount) {
        return formatMileage({units: distanceUnit, value: distanceAmount, process: identity});
    }
    else {
        return distanceUnit === 'KM'
            ? gettext('No free kilometers included')
            : gettext('No free miles included');
    }
}

export function getCarLocationText(location) {
    const {airport, address} = location;
    return compact([
        formatCarAddress(address),
        airport && airport.shuttle
    ]).join('\n');
}

export const parseHotelRate = (hotel, rate) => {
    const {rateValueAdds, bedTypes, total, currency, cancellationPolicy} = rate;
    const {address, city, stateProvinceCode, postalCode, name} = hotel;

    return name + '\n' +
        `${formatAddress(address, city, stateProvinceCode, postalCode)}` + '\n' +
        `${gettext('Amenities:')} ${formatAmenities(rateValueAdds)}` + '\n' +
        `${gettext('Bed Type')}: ${displayBedTypes(bedTypes)}` + '\n' +
        `${gettext('Avg. / Night')}: ${formatAveragePerNight(rate)}` + '\n' +
        `${gettext('Estimated Total')}: ${formatPriceStrict(total, currency)}` +
        `\n${cancellationPolicy || ''}`;
};

const parseCarRateLocation = (pickup, dropoff) => isEqual(pickup, dropoff)
    ? `${gettext('Pick-up/Drop-off Location')}
${getCarLocationText(pickup)}`

    : `${gettext('Pick-up Location')}
${getCarLocationText(pickup)}

${gettext('Drop-off Location')}
${getCarLocationText(dropoff)}`;


export const parseCarRate = (rate) => {
    const {car, vendor, ratePlan, pickup, dropoff} = rate;
    const modelHeader = car.model ? `${gettext('Model')}: ${car.model} ${gettext('or similar')}\n`: '';
    const {amount, type} = ratePlan;
    const ratePlanPrice = formatPrice(amount, rate?.currency);

    return modelHeader + `${gettext('Total Price')}: ${formatPrice(rate?.totalAfterTaxes || rate?.total, rate?.currency)}
${gettext('Rate Plan')}: ${ratePlanPrice} / ${getRatePlanI18N(ratePlan).type}
${gettext('Supplied By')} ${vendor.name} (${vendor.code})
${gettext('ACRISS Code')}: ${car.type} - ${getExplanationText(car.type)}

${parseCarRateLocation(pickup, dropoff)}

${getMiliageText(ratePlan.distanceIncluded).replace(/\*/g, '')}`;
};


export const copyToClipboard = (text) => {
    const dummyTextarea = document.createElement('textarea');

    // Place in the top-left corner of screen regardless of scroll position.
    dummyTextarea.style.position = 'fixed';
    dummyTextarea.style.top = 0;
    dummyTextarea.style.left = 0;

    dummyTextarea.style.width = '2em';
    dummyTextarea.style.height = '2em';

    // Avoid flash of the white box if rendered for any reason.
    dummyTextarea.style.background = 'transparent';


    document.body.appendChild(dummyTextarea);

    dummyTextarea.value = text;
    dummyTextarea.select();
    document?.execCommand('copy');
    document.body.removeChild(dummyTextarea);
};

export const copyHotelOfferInfo = (hotel, rate) => {
    copyToClipboard(parseHotelRate(normalize(hotel), normalize(rate)));
};

export const copyHotelOffers = (offers) => {
    let offersText = '';
    offers.map(({hotel, rate}) => {
        offersText += parseHotelRate(hotel, rate) + '\n\n';
    });

    copyToClipboard(offersText);
};

export const copyCarOffer = rate => copyToClipboard(
    parseCarRate(normalize(rate))
);
