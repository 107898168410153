import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';

import {getPnrByProduct, isMultiPnr} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';

import {showPnr} from 'airborne/store/modules/header/actions/pnr';
import {PNRCopyButton} from "airborne/header2/PNRCopyButton";

function mapStateProps(state) {
    return {
        show: Boolean(getPnrByProduct(state) && !isMultiPnr(state)),
    };
}

@connect(mapStateProps, (dispatch) => ({
    onClick: ()=> dispatch(showPnr(0))
}))
export default class PNRButton extends React.Component {
    static propTypes = {
        show: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
        testId: PropTypes.string,
    };

    handlePnr = event => {
        event.preventDefault();
        this.props.onClick();
    };


    render() {
        const {show} = this.props;
        if (!show) {
            return null;
        }
        return (<a href="#" className="navbar__acc-link" onClick={this.handlePnr}>
            {gettext('PNR Information')}
        </a>);
    }
}


@connect(null, (dispatch) => ({
    onPnrClick: ()=> dispatch(showPnr(0)),
}))
export class PNRWidget extends React.Component {
    static propTypes = {
        pnr: PropTypes.string.isRequired,
        onPnrClick: PropTypes.func.isRequired,
        enablePnrLink: PropTypes.bool,
        enablePnrCopyBtn: PropTypes.bool,
    };

    state = {clicked: false}

    onPnrClick = event => {
        event.preventDefault();
        this.props.onPnrClick();
    };

    render() {
        const {pnr, enablePnrLink, enablePnrCopyBtn, testId} = this.props;

        return (
            <>
                {enablePnrLink ?
                    <a href="#" className="c-confirm__header__pnr-link" onClick={this.onPnrClick} data-testid={testId}>
                        {pnr}
                    </a> :
                    pnr
                }
                {enablePnrCopyBtn && ' '}
                {enablePnrCopyBtn && <PNRCopyButton pnr={pnr}/>}
            </>
        );
    }
}
