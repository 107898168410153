/* eslint-disable immutable/no-mutation */
/* global global */
import some from 'lodash/some';
import map from 'lodash/map';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import settings from 'airborne/settings';
import window from 'airborne/window';

import Request, {abortMatching} from 'airborne/cars/helpers/api/xhr';
import {getCookie, sameOrigin, unsafeMethod} from 'airborne/cars/helpers/api/ajax';
import {applyShackletonMock} from 'airborne/cars/helpers/api/shackleton';
import {getAsCaseNumber} from 'airborne/cars/helpers/api/asCaseNumber';
import {getSsoContainerId} from 'airborne/cars/helpers/api/ssoContainerId';
import SentryAdapter from 'airborne/cars/helpers/api/sentryAdapter';

global.allLoaded = ()=> !global._xhrs.length;

function isFile(item) {
    const FILE_CLASS = global.File;
    return FILE_CLASS && item instanceof FILE_CLASS;
}

function processObjectData(raw) {
    if (!some(raw, isFile)) {
        return JSON.stringify(raw);
    }

    let data = new FormData();

    for (let key in raw) {
        let value = raw[key];

        if (value && value.constructor && value.constructor === Object) {
            data.append(key, JSON.stringify(value));
        }
        else {
            data.append(key, value);
        }
    }

    return data;
}

function processUrl(url, params) {
    if (!url.includes('?')) {
        url += '?';
    }
    let encode = global.encodeURIComponent;
    url += map(params,
        (value, name)=>`${encode(name)}=${encode(value)}`
    ).join('&');

    return url;
}

function isSerializable(data) {
    return (data.constructor &&
        (data.constructor === Object || data.constructor === Array)
    );
}

function getXContextHeader() {
    const asDesktopEnabled = settings['AS_DESKTOP_ENABLED'];
    const asCaseNumber = asDesktopEnabled ? getAsCaseNumber() : null;
    const ssoContainerId = getSsoContainerId();

    const header = {
        ...(ssoContainerId && {'container_id': ssoContainerId}),
        ...((asDesktopEnabled && asCaseNumber) && {'case_number': asCaseNumber}),
    };

    return !isEmpty(header) ? JSON.stringify(header) : null;
}

function urlNotInExclusionsList(url) {
    const excludedUrls = [
        '/maintenance/check-maintenance/',
        '/api/aft_messages/inbox/'
    ];
    return !excludedUrls.includes(url);
}

function api(method, url, {headers={}, ...options}={}) {
    method = method.toUpperCase();

    if (urlNotInExclusionsList(url)) {
        window.dispatchEvent(new Event('update_session_time'));
    }

    const xContextHeader = getXContextHeader();
    const additionalHeaders = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...(xContextHeader && {'X-Context': xContextHeader}),
    };

    if (unsafeMethod(method) && sameOrigin(url)) {
        additionalHeaders['X-CSRFToken'] = getCookie('csrftoken');
    }

    if (options.responseType) {
        options.responseType = options.responseType === 'text' ? '' : options.responseType;
    }
    else {
        options.responseType = 'json';
    }

    const {data} = options;
    if ('data' in options && isSerializable(data)) {
        if (['PATCH', 'POST', 'PUT'].includes(method)) {
            options.data = processObjectData(data);
        }
        else {
            delete options.data;
            url = options.url = processUrl(url, data);
        }
    }
    if (isString(options.data)) {
        additionalHeaders['Content-Type'] = 'application/json; charset=utf-8';
    }

    const request = new Request({
        method,
        url,
        headers: {
            ...additionalHeaders,
            ...headers
        },
        options
    });
    const promise = request.send();

    return {
        then: promise.then.bind(promise),
        catch: promise.catch.bind(promise),
        abort() { request.abort(); },
    };
}

const POLLING_ATTEMPTS_MAX = 40;

function isErrorRelatedToConfigurationId(body={}, options={}) {
    return (body && JSON.stringify(body).indexOf('configuration_id') > -1)
        || (options.data && options.data.hasOwnProperty('configuration_id'));
}

function pollingAPI(method, url, {attempts=POLLING_ATTEMPTS_MAX, ...options}={}) {
    let abort, abortUrl;
    (options && options.id) && abortMatching(options.id);

    const promise = new Promise((resolve, reject)=> {
        function callAPI(method, url, options) {
            const apiPromise = applyShackletonMock(api, {fullResponse: true})(method, url, options);

            apiPromise.then(
                (data)=> {
                    const pollingUrl = data && data.polling_url;
                    abortUrl = abortUrl || (data && data.abort_url);
                    if (pollingUrl) {
                        const nextOptions = omit(options, 'data');

                        // I failed to find better way of timeout emulation
                        if (attempts === 0) { nextOptions.timeout = 1; }
                        return callAPI('GET', pollingUrl, nextOptions);
                    }
                    resolve(data);
                },
                (response)=> {
                    //TODO: GG-32604 should be changed or removed
                    if (response && isErrorRelatedToConfigurationId(response.body, options)) {
                        const error = response?.body?.errors?.[0] || {};

                        //eslint-disable-next-line quotes
                        if (error['developer_message'] === "'configuration_id'"
                            && error['error_code'] === 5001000
                            || JSON.stringify(response).indexOf('"configuration_id":"null"') > -1
                        ) {
                            SentryAdapter.captureException(new Error('Request with wrong configuration_id param.'), {
                                tags: {
                                    'session_id': response['session_id'] || settings.sid,
                                },
                            });
                        }
                    }

                    if (response.request.isTimedOut && abortUrl) {
                        callAPI('POST', abortUrl, {});
                        window.location = '/'; //eslint-disable-line immutable/no-mutation
                    }

                    reject(response);
                }
            );

            abort = apiPromise.abort;
            attempts--;
        }

        callAPI(method, url, options);
    });

    return {
        then: promise.then.bind(promise),
        catch: promise.catch.bind(promise),
        abort() { abort(); },
    };
}

let apiUrl = '';
let isFirstRequest = true;

export default function externalAPI(method, url, options) {
    // eslint-disable-next-line no-undef

    if (!process.env.EXTERNAL_API || !isFirstRequest) {
        const externalUrl = `${apiUrl}${url}`;
        return pollingAPI(method, externalUrl, options);
    }

    isFirstRequest = false;
    const promise = new Promise(async (resolve, reject) => {
        try {
            const settings = await api('GET', '/settings');
            apiUrl = settings.API_URL;
        }
        catch (error) {/* we want to ignore any errors caused by /settings call */}

        const externalUrl = `${apiUrl}${url}`;
        pollingAPI(method, externalUrl, options).then(resolve).catch(reject);
    });

    return {
        then: promise.then.bind(promise),
        catch: promise.catch.bind(promise),
        // We are not allowing abort for the first request to keep things simple.
        abort: noop,
    };
}
externalAPI.abortMatching = abortMatching;
