import {getCompany, getDestForm} from "airborne/store/modules/homepage/selectors/homepage";
import {getPnrProfile} from "airborne/store/modules/homepage/selectors/pnrProfile";

function selectProfile(profile, {traveler}) {
    if (!traveler || traveler.myself) {
        return profile;
    }

    return {
        ...profile,
        'company': traveler.companyId,
        'company_name': traveler.company,
        'tspm_company_id': traveler.tspmCompanyId || null,
        'tspm_traveler_id': traveler.tspmTravelerId || null,
    };
}

export function currentCompany(state) {
    const dest = getDestForm(state);
    const profile = selectProfile(getPnrProfile(state, 0), dest.value);
    const company = getCompany(state);
    return profile ? profile.company : (company?.value || null);
}
