import {
    getSelectedFlightOptionByOD,
    getSelectedFlightOptions
} from 'airborne/store/modules/availability_air/selectors';
import {
    getPricingFormValue,
    getSeparatedTicketsCurrentOD,
    isAirSeparatedTicketsMode
} from 'airborne/store/modules/pricing_air/selectors';
import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {getCurrency} from 'airborne/store/modules/header/selectors/i18n';
import serializePricingRequest from 'airborne/air/pricing/helpers/serialize';
import {getAirExchangeBookingKey, isAirManualExchange} from 'airborne/store/modules/exchange_air/selectors';
import {getSelectedConfiguration} from 'airborne/store/modules/homepage/selectors/tspm';
import {extractSegments} from "airborne/air/availability/helpers/flightOptions";

export const getPricingRequestParams = (state, override = {}) => {
    const {formValue: overrideFormValue} = override;
    const isSeparatedTicketsMode = isAirSeparatedTicketsMode(state);
    const currentOD = getSeparatedTicketsCurrentOD(state);

    const flightOptions = isSeparatedTicketsMode
        ? [getSelectedFlightOptionByOD(state, currentOD)]
        : getSelectedFlightOptions(state);

    const segments = extractSegments(flightOptions);

    const formValue = overrideFormValue || getPricingFormValue(state);
    const profile = getPnrProfile(state, 0);
    const currency = getCurrency(state);
    const exchangeKey = getAirExchangeBookingKey(state);
    const isManualExchange = isAirManualExchange(state);
    const configurationId = getSelectedConfiguration(state);

    return serializePricingRequest({
        flightOptions,
        segments,
        formValue,
        profile,
        currency,
        exchangeKey,
        isManualExchange,
        configurationId,
    });
};
