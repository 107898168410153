'process i18n';
import {apiError} from 'airborne/common/apiError';
import {userMessages} from 'airborne/search2/helpers/apiError';
import {
    LOADED, NET_FAIL,
    LOADING
} from 'midoffice/data/actions/types';
import getErrors from 'midoffice/helpers/apiErrors';
import {dropEmpty} from 'midoffice/helpers/urlParams';
import {parse} from 'airborne/types';
import partial from 'lodash/partial';
import gettext from 'airborne/gettext';
import aio from 'midoffice/helpers/aio';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {isManualRefundError, isVoidedBooking} from './helpers';
import '../types';

import {showError, showInfo, showLoader, hideLoader, hideModal, showModal} from 'midoffice/data/actions/modals';
import {makeGposRequest} from 'airborne/air/checkout/helpers/gposRequest';
import {getAirBookingCompany} from 'midoffice/air-bookings/selectors';
import {loadCompanySettings} from 'airborne/store/modules/homepage/actions/pnr';
import {isGPOSRedirectEnabled} from 'airborne/store/modules/checkout_air/selectors';

const endpoint = 'airBookings';

function informCancellationSuccess() {
    return showInfo(
        gettext('Booking canceled'), 'success'
    );
}

function informCancellationError(error) {
    return showError([
        gettext('Cancelation failed.'),
        ...userMessages(error)
    ]);
}

const timeoutErrorText = () => gettext(
    'Something went wrong. There is a chance that the transaction went through behind the scene. Please try again in few minutes, but only after making sure that the booking is not cancelled.'
);

function fetchEntry(
    endpoint,
    id,
    refreshData = false
) {
    return function (dispatch) {
        dispatch({type: LOADING, endpoint, id});
        return air(
            'GET',
            '/air/get_reservation_details/',
            {
                'booking_uid': id,
                'refresh_data': refreshData,
            },
            'aft'
        )
            .then((data)=> {
                dispatch({type: LOADED, endpoint, id, data: parse(endpoint, data.booking)});
                return data;
            })
            .catch((response)=> {
                dispatch({type: NET_FAIL, endpoint, id, response, errors: getErrors(response)});
            });
    };
}

export const fetchBooking = partial(fetchEntry, endpoint);

export function cancelBooking(uid, system, manualRefundData) {

    const checkCancellationStatus = aio.retry(
        async (uid, system) =>
            await air(
                'GET',
                '/air/get_reservation_details/',
                {
                    'booking_uid': uid,
                },
                system
            ),
        response => response.booking.cancellation_status === 'success',
        2,
        20,
        timeoutErrorText()
    );

    return async function cancelBookingD(dispatch, getState) {
        dispatch({type: 'LOADING', endpoint: 'airBookingModification', id: uid});
        dispatch(showLoader(
            gettext('Canceling reservation')
        ));

        return air(
            'POST',
            '/air/cancel_reservation/',
            dropEmpty({
                'booking_uid': uid,
                'ticket_refund_data': manualRefundData,
            }),
            system,
        )
            .then(() => {
                return checkCancellationStatus(uid, system);
            })
            .then((response) => {
                dispatch({type: 'LOADED', endpoint: 'airBookingModification'});
                dispatch(informCancellationSuccess());
                dispatch(fetchBooking(uid));
                dispatch(hideModal());

                const voidedBooking = isVoidedBooking(response.booking);
                if (!voidedBooking) {
                    dispatch(showModal('airRefundData', {uid}));
                }
            })
            .then(() => {
                const company = getAirBookingCompany(getState(), uid);
                return dispatch(loadCompanySettings(company.id));
            })
            .then(() => {
                if (isGPOSRedirectEnabled(getState())) {
                    makeGposRequest(uid);
                }
            })
            .catch((response) => {
                dispatch({type: 'NET_FAIL', endpoint: 'airBookingModification'});
                const error = apiError(response);
                dispatch(informCancellationError(error));
                dispatch(hideModal());

                if (isManualRefundError(response)) {
                    return dispatch(showModal('airManualRefund', {uid}));
                }
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };
}

function informTicketingSuccess() {
    return showInfo(
        'The tickets have been issued', 'success'
    );
}

function informTicketingError(error) {
    return showError([
        'Ticketing failed.',
        ...userMessages(error)
    ]);
}

export function issueTicket(uid, system) {
    return async function issueTicketD(dispatch) {
        dispatch({type: 'LOADING', endpoint: 'airBookingModification', id: uid});

        return air(
            'POST',
            '/air/documents_issue/',
            {
                'booking_uid': uid,
                'issue_tickets': true,
                'issue_emds': false
            },
            system,
        )
            .then(() => {
                dispatch({type: 'LOADED', endpoint: 'airBookingModification'});
                dispatch(informTicketingSuccess());
                dispatch(fetchBooking(uid));
                dispatch(hideModal());
            })
            .catch((response) => {
                const error = apiError(response);
                dispatch({type: 'NET_FAIL', endpoint: 'airBookingModification', error});
                dispatch(informTicketingError(error));
                dispatch(hideModal());
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    }
}
