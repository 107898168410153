import * as types from '../actionTypes';
import air from 'airborne/store/modules/search_hotels/actions/air';
import {showError} from 'airborne/store/modules/search_hotels/actions';
import {getErrorMessages} from 'airborne/helpers/apiError';
import aio from 'midoffice/helpers/aio';
import {getAvailabilityRequestParams} from 'airborne/store/modules/availability_air/selectors';
import {sendRecentAirSearch} from 'airborne/store/modules/search_air/actions/recentSend';
import {toAvailabilitySearch} from 'airborne/store/modules/availability_air/actions/navigation';
import {setFlightOptionsLoading, setFlightOptions} from './flightOptions';
import {parseAvailability} from 'airborne/air/availability/helpers/parse';
import {getDestFormValue} from 'airborne/store/modules/homepage/selectors/homepage';
import {prefillCheckout} from 'airborne/store/modules/search_air/actions/prefillCheckout';
import {setDefaultFlightOptionSelection} from './selection';
import browserHistory from 'airborne/browserHistory';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';

import {setNgsFareSearchData, setNgsAvailabilityData, resetNgsData} from 'airborne/store/modules/air_ngs_data/actions';
import {getAirRequestParams} from 'airborne/store/modules/search_air/selectors';
import {isRestrictedCountryError} from 'airborne/air/fare_search/helpers/apiError';
import {apiError} from 'airborne/common/apiError';
import {getAirDestValue} from 'airborne/store/modules/confirm_air/selectors';
import {AIR_TRIP_TYPES} from 'airborne/homepage2/types';

const startSearch = (destination) =>
    ({type: types.AIR_AVAILABILITY_START_SEARCH, destination});
const searchError = () => (dispatch) =>
    dispatch({type: types.AIR_AVAILABILITY_FLIGHT_OPTIONS_ERROR});

const makeFareSearchForNgs = () => async (dispatch, getState) => {
    const requestParams = getAirRequestParams(getState());
    const fareSearchResponse = await air('POST', '/air/fare_search/', requestParams);
    dispatch(setNgsFareSearchData(fareSearchResponse));
};

export const makeAvailabilitySearch = (requestParams, destination) => async (dispatch, getState) => {
    const isAirNgsView = getFeatureFlag(getState(), 'AIR_NGS_VIEW');

    dispatch(resetNgsData());
    dispatch(startSearch(destination));
    dispatch(prefillCheckout(destination, true));

    dispatch(setFlightOptionsLoading());
    try {
        if (isAirNgsView) {
            dispatch(makeFareSearchForNgs());
        }

        const response = await air(
            'POST',
            '/air/availability/',
            requestParams
        );

        if (isAirNgsView) {
            dispatch(setNgsAvailabilityData(response));
        }

        const data = parseAvailability(response);
        dispatch(setFlightOptions(data));

        const {originDestinations: destFormODs, tripType} = getAirDestValue(getState());
        const {originDestinations} = data;
        const isMultiCity = tripType === AIR_TRIP_TYPES.MULTI_CITY;
        const flightOptionSelectionRange = isMultiCity
            ? destFormODs.length
            : originDestinations.length;

        dispatch(setDefaultFlightOptionSelection(flightOptionSelectionRange));
    }
    catch (response) {
        dispatch(searchError());

        if (isRestrictedCountryError(response)) {
            dispatch(showError(apiError(response)[0], 'restrictedDestination'));
        } else {
            dispatch(showError(getErrorMessages(response), 'search'));
        }
    }
};

export const requestAvailability = () => async (dispatch, getState) => {
    sendRecentAirSearch(getState());

    const requestParams = getAvailabilityRequestParams(getState());
    const destination = getDestFormValue(getState());

    const searchPromise = dispatch(makeAvailabilitySearch(requestParams, destination));
    await Promise.race([searchPromise, aio.sleep(2)]);

    browserHistory.push('/ui/air_availability/');
    return dispatch(toAvailabilitySearch());
};
