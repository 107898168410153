import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import cookie from 'js-cookie';
import identity from 'lodash/identity';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';

const SHACKLETON_BASE_URL = 'https://shackleton.staging.bcdtriptech.com/fancy/aft_fe_mocks_poc';
const SHACKLETON_BAYMONT_SERVICE_NAME = 'baymont';
const SHACKLETON_ASB_SERVICE_NAME = 'asb';

const SHACKLETON_BASE_URL_COOKIE = 'shackleton_base_url';
const SHACKLETON_URL_PATTERNS_COOKIE = 'shackleton_url_patterns';
const MOCK_ID_COOKIE = 'mock_id';

function getUrlPattenrs() {
    const patternsString = cookie.get(SHACKLETON_URL_PATTERNS_COOKIE) || '';
    return patternsString.split(',').filter(identity);
}

function shouldRetrieveOriginal(patterns, url) {
    return !isEmpty(patterns)
        && !some(patterns, pattern => (new RegExp(pattern)).exec(url));
}

function getShackletonUrl(url) {
    const urlPatterns = getUrlPattenrs();

    if (shouldRetrieveOriginal(urlPatterns, url)) {
        return null;
    }

    const baseUrl = cookie.get(SHACKLETON_BASE_URL_COOKIE) || SHACKLETON_BASE_URL;
    const service = url.includes('baymont')
        ? SHACKLETON_BAYMONT_SERVICE_NAME
        : SHACKLETON_ASB_SERVICE_NAME;
    return [baseUrl, service, ''].join('/');
}

export function applyShackletonMock(
    apiF,
    {
        parseIn=identity,
        parseOut=identity,
        fullResponse=false
    }={},
) {
    return function (...args) {
        const [method, url, {headers = {}, ...options} = {}] =  parseIn(args);

        const shackletonUrl = getShackletonUrl(url);
        if (!getFeatureFlag(null, 'QA_ENABLE_SHACKLETON_FOR_FE', {canGetFromCookie: true}) || !shackletonUrl) {
            return apiF(...args);
        }

        const mockId = cookie.get(MOCK_ID_COOKIE);
        const pathedOptions = {
            headers: {
                ...headers,
                'X-original-url': url,
                ...(mockId ? {'X-mock-id': mockId} : {}),
            },
            ...options,
            ...(fullResponse ? {fullResponse: true} : {}),
        };

        return apiF(...parseOut([method, shackletonUrl, pathedOptions]))
            .then((response) => {
                if (response.status === 344) {
                    return apiF(...args);
                }
                return fullResponse ? response.body : response;
            })
            .catch((input) => {
                const response = apiF.Axios ? input.response : input;
                if (!response) {
                    return;
                }
                if (response.status === 344) {
                    return apiF(...args);
                }
                return Promise.reject(input);
            });
    };
};
