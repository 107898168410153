import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import settings from 'airborne/settings';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Glyphicons from 'midoffice/components/Glyphicons';

import CurrencyDropdown from 'airborne/header2/CurrencyDropdown';
import LocaleDropdown from 'airborne/header2/LocaleDropdown';
import PNRButton from 'airborne/header2/PNRButton';
import PNRModWarning from 'airborne/homepage2/PNRModWarning';
import Inbox from 'airborne/header2/Inbox';
import ActionableCounter from 'airborne/common/ActionableCounter';

import {isModification} from 'airborne/store/modules/homepage/selectors/pnrProfile';
import {isAirExchangeActive} from 'airborne/store/modules/exchange_air/selectors';

const SUPPORT_LINK = 'https://getgoing1.zendesk.com/hc/en-us';
import session from 'airborne/session';

import {sendLogout} from 'airborne/store/modules/logout/actions/logout';
import Shortcut from 'midoffice/components/shortcut/Shortcut';
import {SHORTCUTS_LIST} from 'midoffice/components/shortcut/helper';
import browserHistory from 'airborne/browserHistory';
import StorageService from 'airborne/services/Storage';
import {getFeatureFlag} from 'airborne/store/modules/featureFlags/selector';
import {getCarModificationFlowBool} from 'midoffice/car-bookings/selectors';
import {hasAccess} from 'midoffice/helpers/permission';

const popupStorage = new StorageService('local', 'actionableBookings');
const countStorage = new StorageService('local', 'actionableBookingsCount');

function setActionablePopup(read) {
    popupStorage.setItem({actionableBookings: read});
}

function setActionableCounter(count) {
    countStorage.setItem({actionableBookingsCount: count});
}

function getActionablePopup() {
    const defaultValue = {actionableBookings: false};
    return popupStorage.getItem() || defaultValue;
}

function getActionableCount() {
    const defaultValue = {actionableBookingsCount: 0};
    return countStorage.getItem() || defaultValue;
}

function mapStateProps(state) {
    return {
        modification: isModification(state),
        carBookingModification: getCarModificationFlowBool(state),
        airExchangeActive: isAirExchangeActive(state),
        actionableCounter: state.actionableCounter,
        skipAbrPopup: getFeatureFlag(state, 'QA_SKIP_ABR_POPUP'),
        silverdoorEnabled: getFeatureFlag(state, 'SILVERDOOR_ENABLED'),
    };
}

@connect(mapStateProps, {
    logout: sendLogout,
})
export default class Header extends React.Component {
    static propTypes = {
        modification: PropTypes.bool,
        airExchangeActive: PropTypes.bool,
        logout: PropTypes.func,
        actionableCounter: PropTypes.number,
        skipAbrPopup: PropTypes.bool,
        silverdoorEnabled: PropTypes.bool,
        carBookingModification: PropTypes.bool,
    };

    state = {
        viewedNotification: getActionablePopup()?.actionableBookings,
    };

    componentDidMount() {
        const newCount = this.getCount();
        const oldCount = getActionableCount() || {};
        if (
            oldCount &&
            oldCount?.actionableBookingsCount !== newCount &&
            newCount > oldCount?.actionableBookingsCount
        ) {
            setActionablePopup(false);
            setActionableCounter(newCount);
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({viewedNotification: false});
        }
    }

    shortcuts = {
        home: SHORTCUTS_LIST.E,
        logout: SHORTCUTS_LIST.LOGOUT,
        settings: SHORTCUTS_LIST.SETTINGS,
        records: SHORTCUTS_LIST.B,
    };

    gotoSettings() {
        browserHistory.push('/ui/profile-settings/');
    }

    gotoHome() {
        browserHistory.push('/ui/homepage/');
    }

    closeActionablePopup = () => {
        setActionablePopup(true);
        setActionableCounter(this.getCount());
        this.setState({
            viewedNotification: true,
        });
    };

    gotoRecords = event => {
        event.preventDefault();
        const isParentClick = event.target === event.currentTarget;
        if (isParentClick) {
            browserHistory.push('/bookings/hotels/');
            this.closeActionablePopup();
        }
    };

    goToRecordsFromAbrPopUp = () => {
        const agentsCheckBoxChecked = this.getNotificationState();
        this.closeActionablePopup();
        browserHistory.push('/bookings/hotels/', {checkedBox: agentsCheckBoxChecked});
    };

    getCount = () => {
        const {actionableCounter} = this.props;
        return Number.isInteger(actionableCounter) ? actionableCounter : settings['ACTIONABLE_BOOKINGS'];
    };

    getNotificationState = () => {
        const count = this.getCount();
        const isAft = settings['SUB_PARTNER'] === 'aft' || settings['DISPLAY_MODE'] === 'aft';
        return count > 0 && isAft && !this.state.viewedNotification;
    };

    handleClick() {
        session.set('sid', '');
    }

    handleOnCloseClick = event => {
        event.preventDefault();
        event.stopPropagation();
        this.closeActionablePopup();
    };

    handleLogout = () => {
        this.props.logout();
    };

    renderBookingRecordsLink = () => {
        const {skipAbrPopup} = this.props;
        const shouldShowNotification = this.getNotificationState();

        if (!hasAccess('aft:booking_records:edit')) {
            return null;
        }

        return (
            <>
                <NavItem className="nav-unstyled nav-popover no-margin" as="li" id="booking-records-link">
                    <NavLink onClick={this.gotoRecords}>
                        <ActionableCounter>
                            <Shortcut
                                label={gettext('Booking Records')}
                                shortcut={this.shortcuts.records}
                                action={this.gotoRecords}
                            />
                        </ActionableCounter>
                        {Boolean(shouldShowNotification) && !skipAbrPopup && (
                            <div className="feature-tooltip feature-tooltip--condensed feature-tooltip--bottom">
                                <Button
                                    variant="link"
                                    size="sm"
                                    className="feature-tooltip__control"
                                    onClick={this.handleOnCloseClick}
                                >
                                    <Glyphicons bsClass={'glyphicons'} glyph={'remove-2'} />
                                </Button>
                                <h2 className="feature-tooltip__title">
                                    {gettext('Some bookings need your attention')}
                                </h2>
                                <footer className="feature-tooltip__footer">
                                    <Button variant="link" name="openMessage" onClick={this.goToRecordsFromAbrPopUp}>
                                        {gettext('Click here to view')}
                                    </Button>
                                </footer>
                            </div>
                        )}
                    </NavLink>
                </NavItem>
            </>
        );
    };

    renderMenu() {
        const {'first_name': name} = settings.USER;
        const {silverdoorEnabled} = this.props;
        return [
            <Nav key={0} name="links" as="ul" className="nav">
                {silverdoorEnabled && (
                    <NavItem as="li" className="navbar__ext-link" data-heap="heap-silverdoor">
                        <NavLink href="https://bcdtravel.silverdoorapartments.com/" target="_blank">
                            {gettext('SilverDoor')} <span className="glyphicons glyphicons-new-window" />
                        </NavLink>
                    </NavItem>
                )}
                {this.renderBookingRecordsLink()}
            </Nav>,
            <Nav className="pull-right" key={1} as="ul" className="nav pull-right">
                <CurrencyDropdown />
                <LocaleDropdown />
                <NavDropdown title={name} align="end" id="user-menu" as="li">
                    <NavDropdown.Item onBlur={this.gotoSettings}>
                        <Shortcut
                            label={gettext('My Settings')}
                            shortcut={this.shortcuts.settings}
                            action={this.gotoSettings}
                            isInherit
                        />
                    </NavDropdown.Item>
                    <NavDropdown.Item href={SUPPORT_LINK} eventKey={3.2} target="_blank">
                        {gettext('Support')}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={this.handleLogout}>
                        <Shortcut
                            label={gettext('Sign Out')}
                            shortcut={this.shortcuts.logout}
                            action={this.props.logout}
                            isInherit
                        />
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>,
            <Inbox key={2} />,
        ];
    }

    renderPNRMod() {
        return [
            <Nav className="nav pull-right" key={1} as="ul">
                <CurrencyDropdown />
                <LocaleDropdown />
            </Nav>,
            <PNRModWarning key={2} />,
        ];
    }

    render() {
        const {modification, airExchangeActive, carBookingModification} = this.props;
        const activeModificationFlow = modification || carBookingModification;

        return (
            <Navbar bg="light" className={({'navbar-exchange': airExchangeActive}, 'navbar-default')}>
                <Container>
                    <div className="navbar-header" onClick={this.handleClick}>
                        <Navbar.Brand href="/" className="logo" />
                    </div>
                    {activeModificationFlow ? this.renderPNRMod() : this.renderMenu()}
                    <PNRButton />
                </Container>
            </Navbar>
        );
    }
}
