import {getPnrProfile} from 'airborne/store/modules/homepage/selectors/pnrProfile.js';
import {noPnrEnabled, guestTravelerEnabled} from 'airborne/air/shared/selectors/hyatt';

export function hasPnr(profile) {
    return Boolean(profile && profile.pnr);
}

function hasTraveler(profile) {
    const {'tspm_traveler_id': traveler} = profile || {};
    return Boolean(traveler);
}

function checkPNR(state, idx) {
    return hasPnr(getPnrProfile(state, idx)) || noPnrEnabled(state);
}

function checkTraveler(state, idx) {
    return hasTraveler(getPnrProfile(state, idx)) || guestTravelerEnabled(state);
}

export function isBookable(state, idx) {
    return checkPNR(state, idx) && checkTraveler(state, idx);
}

export function isSameCompany(profile, company) {
    return !company || company.value === profile.company;
}
